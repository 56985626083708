import { Button } from '../catalyst/button'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../catalyst/dialog'
import { Field, FieldGroup, Label, Description, ErrorMessage } from '../catalyst/fieldset'
import { Input } from '../catalyst/input'
import { useState, useEffect } from 'react'
import { Select } from '../catalyst/select'
import { useMutation, gql } from '@apollo/client'
import { InformationCircleIcon, PlusIcon } from '@heroicons/react/16/solid'
import { Tooltip } from 'react-tooltip'
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../catalyst/alert'
import CreateProgramModal from './CreateProgram'

const ENROLL_STUDENT = gql`
  mutation CreateEnrollment($input: CreateEnrollmentInput!) {
    createEnrollment(input: $input) {
      enrollment {
        id
      }
    }
  }
`

export default function CreateEnrollment({ programs, student, refetch, refetchPrograms, ...props }) {
  let [isOpen, setIsOpen] = useState(false)
  const [enrollmentStartDate, setEnrollmentStartDate] = useState(new Date().toISOString().split('T')[0])
  const [firstPaymentDate, setFirstPaymentDate] = useState(enrollmentStartDate)
  const [rate, setRate] = useState('')
  const [rateFrequency, setRateFrequency] = useState()
  const [program, setProgram] = useState('')
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [error, setError] = useState('')
  const [enrollStudent, { loading }] = useMutation(ENROLL_STUDENT, {
    onCompleted: () => {
      setIsOpen(false)
      refetch()
    },
    onError: (error) => {
      setError(error.message)
      setErrorDialogOpen(true)
    }
  })

  useEffect(() => {
    if (program) {
      const selectedProgram = programs.find(p => p.id === program)
      if (selectedProgram) {
        setRate(selectedProgram.defaultRate || '')
        setRateFrequency(selectedProgram.defaultRateFrequency || '')
      }
    }
  }, [program, programs])

  const validateForm = () => {
    const newErrors = {}
    if (!program) newErrors.program = 'Program is required'
    if (!enrollmentStartDate) newErrors.enrollmentStartDate = 'Start date is required'
    if (!firstPaymentDate) newErrors.firstPaymentDate = 'First payment date is required'
    if (!rate || rate <= 0) newErrors.rate = 'Valid rate is required'
    if (!rateFrequency) newErrors.rateFrequency = 'Billing frequency is required'
    return newErrors
  }

  const handleEnrollStudent = (e) => {
    e.preventDefault()
    
    const newErrors = validateForm()
    setErrors(newErrors)
    setShowErrors(true)

    if (Object.keys(newErrors).length === 0) {
      enrollStudent({ 
        variables: { 
          input: { 
            student: student.uuid, 
            program, 
            enrollmentStartDate, 
            firstPaymentDate, 
            rate: Number(rate), 
            rateFrequency,
          } 
        } 
      })
    }
  }

  return (
    <>
      <Button outline type="button" onClick={() => setIsOpen(true)}>
        <PlusIcon className="h-4 w-4" /> Add Enrollment
      </Button>
      <Alert open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          {error}
        </AlertDescription>
        <AlertActions>
          <Button color="red" onClick={() => setErrorDialogOpen(false)}>Close</Button>
        </AlertActions>
      </Alert>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Add an Enrollment</DialogTitle>
        <DialogDescription>
          Add an enrollment for this student.
        </DialogDescription>
        <form onSubmit={handleEnrollStudent}>
          <DialogBody>
            <FieldGroup>
              <div className="flex flex-col gap-4">
                <Field>
                  <Label>Program</Label>
                <Select 
                  value={program} 
                  onChange={(e) => setProgram(e.target.value)}
                  invalid={showErrors && !!errors.program}
                >
                  <option value="" disabled>Select a Program</option>
                  {programs.map(program => (
                    <option key={program.id} value={program.id}>{program.name}</option>
                  ))}
                </Select>
                {showErrors && errors.program && <ErrorMessage>{errors.program}</ErrorMessage>}
              </Field>
              <CreateProgramModal refetch={refetchPrograms} buttonText="Create New Program" />
              </div>
              <div className="grid grid-cols-2 gap-4">
              <Field>
              <div className="flex items-center gap-1">
                  <Label>
                    Start Date
                  </Label>
                  <InformationCircleIcon 
                    className="h-3 w-3 text-gray-400 cursor-help"
                    data-tooltip-id="enrollment-start-date-tooltip"
                    data-tooltip-content="The date the student will start the program."
                  />
                  <Tooltip id="enrollment-start-date-tooltip" />
                </div>
                <Input 
                  type="date" 
                  value={enrollmentStartDate} 
                  onChange={(e) => setEnrollmentStartDate(e.target.value)}
                  invalid={showErrors && !!errors.enrollmentStartDate}
                />
                {showErrors && errors.enrollmentStartDate && <ErrorMessage>{errors.enrollmentStartDate}</ErrorMessage>}
              </Field>
              <Field>
                <div className="flex items-center gap-1">
                <Label>
                  First Payment Date
                  </Label>
                  <InformationCircleIcon 
                    className="h-3 w-3 text-gray-400 cursor-help"
                    data-tooltip-id="first-payment-date-tooltip"
                    data-tooltip-content="Future due dates will be calculated based on this date and the billing frequency."
                  />
                  <Tooltip id="first-payment-date-tooltip" />
                </div>
                <Input 
                  type="date" 
                  value={firstPaymentDate} 
                  onChange={(e) => setFirstPaymentDate(e.target.value)}
                  invalid={showErrors && !!errors.firstPaymentDate}
                />
                {showErrors && errors.firstPaymentDate && <ErrorMessage>{errors.firstPaymentDate}</ErrorMessage>}
              </Field>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Field>
                  <Label className="flex items-center gap-1">
                    Rate ($)
                  </Label>
                  <Input 
                    type="number" 
                    value={rate} 
                    onChange={(e) => setRate(e.target.value)}
                    invalid={showErrors && !!errors.rate}
                  />
                  {showErrors && errors.rate && <ErrorMessage>{errors.rate}</ErrorMessage>}
                </Field>
                <Field>
                  <Label>Billing Frequency</Label>
                  <Select 
                    value={rateFrequency} 
                    onChange={(e) => setRateFrequency(e.target.value)}
                    invalid={showErrors && !!errors.rateFrequency}
                  >
                    <option value="" disabled>Select Frequency</option>
                    <option value="One Time">One Time</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </Select>
                  {showErrors && errors.rateFrequency && <ErrorMessage>{errors.rateFrequency}</ErrorMessage>}
                </Field>
              </div>
            </FieldGroup>
          </DialogBody>
          <DialogActions>
            <Button type="button" plain onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button type="submit" color="sprout" disabled={loading}>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
