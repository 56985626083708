import React from 'react';
import clsx from 'clsx';

const Card = React.forwardRef(({ children, className, ...props }, ref) => (
  <div ref={ref} className={clsx('bg-white rounded-lg shadow-sm ring-1 ring-zinc-950/5 dark:bg-zinc-900 dark:ring-white/10', className)} {...props}>
    {children}
  </div>
));

Card.displayName = 'Card';

Card.Header = function CardHeader({ className, children, ...props }) {
  return (
    <div
      className={clsx('px-6 py-4 border-b border-zinc-200 dark:border-zinc-800', className)}
      {...props}
    >
      {children}
    </div>
  );
};

Card.Body = function CardBody({ className, children, ...props }) {
  return (
    <div className={clsx('p-6', className)} {...props}>
      {children}
    </div>
  );
};

Card.Title = function CardTitle({ className, children, ...props }) {
  return (
    <h2
      className={clsx('text-lg font-semibold text-zinc-900 dark:text-white', className)}
      {...props}
    >
      {children}
    </h2>
  );
};

export default Card;