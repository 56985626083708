import { Button } from "../../components/catalyst/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../../components/catalyst/dialog";
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from "../../components/catalyst/fieldset";
import Spinner from "../../components/catalyst/spinner";
import { Input } from "../../components/catalyst/input";
import { Select } from "../../components/catalyst/select";
import { useEffect, useState } from "react";
import GoogleSearch from '../../components/address-input';
import { useLocation } from "wouter";
import { useMutation, gql, useLazyQuery } from '@apollo/client';
import toast, { Toaster } from 'react-hot-toast';

const CREATE_STUDENT = gql`
  mutation CreateStudent(
    $firstName: String!
    $lastName: String!
    $dateOfBirth: Date!
    $gender: String!
    $address: JSONString!
    $family: ID!
  ) {
    createStudent(
      input: {
        firstName: $firstName
        lastName: $lastName
        dateOfBirth: $dateOfBirth
        gender: $gender
        address: $address
        family: $family
      }
    ) {
      student {
        uuid
      }
    }
  }
`;

const SEARCH_FAMILIES = gql`
  query SearchFamilies($name: String!) {
    searchFamilies(name: $name) {
      uuid
      name
      studentSet {
        edges {
          node {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export function CreateStudentModal({ ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const [location, navigate] = useLocation();
  const [address, setAddress] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    address: "",
    family: "",
  });

  const [recommendedFamilies, setRecommendedFamilies] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState("");

  const [searchFamilies, { data: familiesData }] = useLazyQuery(SEARCH_FAMILIES);

  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      address: address,
    }));
  }, [address]);

  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      family: selectedFamily,
    }));
  }, [selectedFamily]);

  useEffect(() => {
    if (form.last_name.length > 2) {
      searchFamilies({ variables: { name: form.last_name } });
    }
  }, [form.last_name, searchFamilies]);

  useEffect(() => {
    if (familiesData && familiesData.searchFamilies) {
      setRecommendedFamilies(familiesData.searchFamilies);
    }
  }, [familiesData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setForm({
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "",
      address: null,
      family: "",
    });
    setIsOpen(false);
  };

  const [createStudent] = useMutation(CREATE_STUDENT, {
    onCompleted: (data) => {
      toast.dismiss('savingStudent');
      toast.success('Student created successfully!');
      handleClose();
      if (data.createStudent && data.createStudent.student) {
        navigate(`/students/${data.createStudent.student.uuid}`);
      } else {
        console.error('Student ID not found in the response');
      }
    },
    onError: (error) => {
      toast.dismiss('savingStudent');
      toast.error('Error creating student: ' + error.message);
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    toast.loading('Creating student...', { id: 'savingStudent' });

    try {
      const formattedAddress = typeof form.address === 'string' 
        ? form.address 
        : JSON.stringify(form.address);

      await createStudent({
        variables: {
          firstName: form.first_name,
          lastName: form.last_name,
          dateOfBirth: form.date_of_birth,
          gender: form.gender,
          address: formattedAddress,
          family: form.family,
        },
      });
    } catch (error) {
      console.error('Error creating student:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Button
        color="sprout"
        className="min-w-fit"
        type="button"
        onClick={() => setIsOpen(true)}
        {...props}
      />
      <Dialog open={isOpen} onClose={setIsOpen}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create student</DialogTitle>
          <DialogDescription>
            Create a new student to begin the enrollment process.
          </DialogDescription>
          <DialogBody>
            <Fieldset>
              <FieldGroup>
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
                  <Field>
                    <Label>First name</Label>
                    <Input
                      name="first_name"
                      placeholder="Jon"
                      autoFocus
                      onChange={handleChange}
                    />
                  </Field>
                  <Field>
                    <Label>Last name</Label>
                    <Input
                      name="last_name"
                      placeholder="Doe"
                      onChange={handleChange}
                    />
                  </Field>
                  <Field>
                    <Label>Date of Birth</Label>
                    <Input
                      type="date"
                      name="date_of_birth"
                      onChange={handleChange}
                    />
                  </Field>
                  <Field>
                    <Label>Gender</Label>
                    <Select name="gender" onChange={handleChange}>
                      <option disabled selected>
                        Select a gender&hellip;
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Select>
                  </Field>
                </div>
                <Field>
                  <Label>Address</Label>
                  <GoogleSearch name="address" onChange={setAddress} />
                </Field>
                {recommendedFamilies.length > 0 && (
                  <Field>
                    <Label>Recommended Families</Label>
                    <Select
                      name="family"
                      onChange={(e) => setSelectedFamily(e.target.value)}
                      value={selectedFamily}
                    >
                      <option value="">Select a family...</option>
                      {recommendedFamilies.map((family) => (
                        <option key={family.uuid} value={family.uuid}>
                          {family.name} ({family.studentSet.edges.map(edge => 
                            `${edge.node.firstName} ${edge.node.lastName}`).join(', ')})
                        </option>
                      ))}
                    </Select>
                  </Field>
                )}
              </FieldGroup>
            </Fieldset>
          </DialogBody>
          <DialogActions>
            <Button outline onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={submitting}
              color="sprout"
            >
              {!submitting ? "Create" : <Spinner />}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Toaster/>
    </>
  );
}
