import "./App.css";
import { useAuth } from "./hooks/useAuth";
import { Router, Route, Switch, Redirect } from "wouter";
import AuthenticatedUserLayout from "./layouts/AuthenticatedUserLayout/AuthenticatedUserLayout";
import AuthenticationPage from "./pages/AuthenticationPage/AuthenticationPage";
import Spinner from "./components/catalyst/spinner";
import OnboardingPage from "./pages/OnboardingPage/OnboardingPage";
import { useUser } from "./hooks/useUser";
import StudentsPage from "./pages/StudentsPage/StudentsPage";
import StudentPage from "./pages/StudentPage/StudentPage";

function App() {
  const { isAuthenticated, authLoaded, login } = useAuth();
  const { user } = useUser();

  const Loader = () => {
    return (
      <div className="relative isolate flex min-h-svh w-full bg-white max-lg:flex-col lg:bg-zinc-100 dark:bg-zinc-900 dark:lg:bg-zinc-950">
        <main className="m-auto pb-2 lg:min-w-0 lg:px-2 lg:pt-2">
          <div className="grow p-6 lg:rounded-lg lg:bg-white lg:p-10 lg:shadow-sm lg:ring-1 lg:ring-zinc-950/5 dark:lg:bg-zinc-900 dark:lg:ring-white/10">
            <div className="flex flex-col grow items-center mx-auto sm:w-[400px] space-y-6">
              <Spinner />
              <p className="text-sm font-light text-zinc-500">
                Getting everything ready for you...
              </p>
            </div>
          </div>
        </main>
      </div>
    )
  }

  return (
    <>
      {authLoaded ? (
        <Router>
          {isAuthenticated ? (
            user ? (
              <AuthenticatedUserLayout>
                {!user.organization.accountSetupComplete ? (
                  <Switch>
                    <Route path="/" exact>
                      <OnboardingPage />
                    </Route>
                    <Redirect to="/" />
                  </Switch>
                ) : (
                  <>
                    <Switch>
                      <Route path="/" exact>
                      </Route>
                      <Route path="/students" exact>
                        <StudentsPage />
                      </Route>
                      <Route path="/students/:id/:tab?" exact>
                        <StudentPage />
                      </Route>
                    </Switch>
                  </>
                )}
              </AuthenticatedUserLayout>
            ) : (
              <>
              {Loader()}
              </>
            )
          ) : (
            <Switch>
              <Route path="*">
                <AuthenticationPage />
              </Route>
            </Switch>
          )}
        </Router>
      ) : (
        <>
          {Loader()}
        </>
      )}
    </>
  );
}

export default App;
