import { Button } from '../../components/catalyst/button'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../components/catalyst/dialog'
import { Field, FieldGroup, Label, Description, ErrorMessage } from '../../components/catalyst/fieldset'
import { Input } from '../../components/catalyst/input'
import { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline'
import { Text } from '../../components/catalyst/text'
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../components/catalyst/alert'
import toast, { Toaster } from "react-hot-toast";
import { RadioGroup, RadioField, Radio } from '../../components/catalyst/radio'

const CREATE_REFUND = gql`
  mutation CreateRefund($input: CreateRefundInput!) {
    createRefund(input: $input) {
      refund {
        id
      }
    }
  }
`;

export default function CreateRefund({ invoice, payment, family, refetch, ...props }) {
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toISOString().split('T')[0];
  };

  const [isOpen, setIsOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [refundDate, setRefundDate] = useState(formatDateForInput(new Date()))
  const [amount, setAmount] = useState(props.remainingAmount)
  const [paymentMethod, setPaymentMethod] = useState(payment.paymentMethodType.toLowerCase())
  const [refundReason, setRefundReason] = useState('')

  const maxRefundAmount = props.remainingAmount || 0;

  const [createRefund, { loading }] = useMutation(CREATE_REFUND, {
    onCompleted: () => {
      setIsOpen(false)
      refetch()
    }
  })

  const handleClose = () => {
    setIsOpen(false)
    setErrors({})
    setShowErrors(false)
  }

  const validateForm = () => {
    const newErrors = {}
    if (!refundDate) newErrors.refundDate = 'Refund date is required'
    if (!amount || amount <= 0) newErrors.amount = 'Valid amount is required'
    if (!refundReason.trim()) newErrors.refundReason = 'Reason for refund is required'
    return newErrors
  }

  const handleSave = (e) => {
    e.preventDefault()
    
    const newErrors = validateForm()
    setErrors(newErrors)
    setShowErrors(true)

    if (Object.keys(newErrors).length === 0) {
      createRefund({
        variables: {
          input: {
            payment: payment.id,
            amount: parseFloat(amount),
            refundDate,
            paymentMethod,
            reason: refundReason
          }
        }
      }).then(() => {
        toast.success('Refund processed successfully')
      }).catch((error) => {
        toast.error(error.message)
      })
    }
  }

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    if (method === 'card') {
      setRefundDate(formatDateForInput(new Date()));
    }
  }

  return (
    <>
      <Button outline type="button" onClick={() => setIsOpen(true)}>
        {props.children}
      </Button>
      <Dialog size='md' open={isOpen} onClose={handleClose}>
        <DialogTitle>Process Refund</DialogTitle>
        <DialogDescription>
          Process a refund for payment of ${payment.amount} on {new Date(payment.date).toLocaleDateString()}
        </DialogDescription>
        <form onSubmit={handleSave}>
          <DialogBody>
            <FieldGroup>
              <Field>
                <Label>Payment Method</Label>
                <RadioGroup 
                  value={payment.paymentMethodType} 
                  onChange={handlePaymentMethodChange}
                  disabled={true}
                >
                  {payment.paymentMethodType === 'Card' ? (
                    <RadioField>
                      <Radio value="Card" />
                      <Label>Card ({family.stripePaymentMethodName})</Label>
                    </RadioField>
                  ) : (
                    <RadioField>
                      <Radio value="Manual"/>
                      <Label>Cash/Check/Other</Label>
                    </RadioField>
                  )}
                </RadioGroup>
              </Field>

              <Field>
                <Label>Refund Date</Label>
                <Input 
                  type="date" 
                  value={refundDate} 
                  onChange={(e) => setRefundDate(e.target.value)}
                  invalid={showErrors && !!errors.refundDate}
                  disabled={paymentMethod === 'card'}
                />
                {showErrors && errors.refundDate && <ErrorMessage>{errors.refundDate}</ErrorMessage>}
              </Field>

              <Field>
                <Label>Reason for Refund</Label>
                <Input 
                  type="text"
                  value={refundReason}
                  onChange={(e) => setRefundReason(e.target.value)}
                  invalid={showErrors && !!errors.refundReason}
                  placeholder="Please provide a reason for this refund"
                />
                {showErrors && errors.refundReason && <ErrorMessage>{errors.refundReason}</ErrorMessage>}
              </Field>

              <Field>
                <Label>Amount ($)</Label>
                <Input
                  type="number"
                  step="0.01"
                  value={maxRefundAmount}
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                  max={maxRefundAmount}
                  required
                />
                <Text className="text-sm text-gray-500">
                  Maximum refund amount: ${maxRefundAmount}
                </Text>
              </Field>
            </FieldGroup>
          </DialogBody>
          <DialogActions>
            <Button plain onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" color="sprout" disabled={loading}>
              {paymentMethod === 'card' ? 'Process Refund' : 'Record Refund'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Toaster />
    </>
  )
}
