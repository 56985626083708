import { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { usePostHog } from 'posthog-js/react';
import { useAuth } from "../hooks/useAuth";
import { gql, useQuery } from "@apollo/client";

const GET_USER = gql`
{
  user {
    id
    email
    firstName
    lastName
    avatar
    role
    organization {
      id
      uuid
      email
      name
      phoneNumber
      stripeAccountId
      stripeOnboardingComplete
      accountSetupComplete
      accountSetupCurrentStep
      timezone
      address
      logoUrl
    }
  }}
`;

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const { loading, error, data, refetch } = useQuery(GET_USER);
  const [user, setUser] = useState(null);
  const [org, setOrg] = useState(null); // Added this line
  const { isAuthenticated } = useAuth();
  const posthog = usePostHog();

  useEffect(() => {
    if (data?.user) {
      setUser(data.user);
      setOrg(data.user.organization);
      posthog.identify(data.user.id, {
        email: data.user.email,
        name: data.user.firstName + " " + data.user.lastName,
      });
    }
  }, [data, posthog]);

  useEffect(() => {
    refetch();
  }, [isAuthenticated, refetch]);

  if (error) {
    console.error("Apollo Error:", error);
  }

  return (
    <UserContext.Provider
      value={{ user, org, refetch }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserProvider };
export default UserContext;