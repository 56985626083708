import { Button } from '../../components/catalyst/button'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../components/catalyst/dialog'
import { Field, FieldGroup, Label, Description, ErrorMessage } from '../../components/catalyst/fieldset'
import { Input } from '../../components/catalyst/input'
import { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline'
import { Text } from '../../components/catalyst/text'
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../components/catalyst/alert'
import toast, { Toaster } from "react-hot-toast";
import { RadioGroup, RadioField, Radio } from '../../components/catalyst/radio'

const CREATE_PAYMENT = gql`
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      payment {
        id
      }
    }
  }
`;

export default function CreatePayment({ invoice, family, refetch, ...props }) {
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toISOString().split('T')[0];
  };

  const [isOpen, setIsOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [paymentDate, setPaymentDate] = useState(formatDateForInput(new Date()))
  const [amount, setAmount] = useState(invoice.total - invoice.amountPaid)
  const [paymentMethod, setPaymentMethod] = useState('cash')

  const [createPayment, { loading }] = useMutation(CREATE_PAYMENT, {
    onCompleted: () => {
      setIsOpen(false)
      refetch()
    }
  })

  const handleClose = () => {
    setIsOpen(false)
    setErrors({})
    setShowErrors(false)
  }

  const validateForm = () => {
    const newErrors = {}
    if (!paymentDate) newErrors.paymentDate = 'Payment date is required'
    if (!amount || amount <= 0) newErrors.amount = 'Valid amount is required'
    return newErrors
  }

  const handleSave = (e) => {
    e.preventDefault()
    
    const newErrors = validateForm()
    setErrors(newErrors)
    setShowErrors(true)

    if (Object.keys(newErrors).length === 0) {
      createPayment({
        variables: {
          input: {
            invoice: invoice.uuid,
            amount: parseFloat(amount),
            paymentDate,
            paymentMethod
          }
        }
      }).then(() => {
        toast.success('Payment recorded successfully')
      }).catch((error) => {
        toast.error(error.message)
      })
    }
  }

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    if (method === 'card') {
      setPaymentDate(formatDateForInput(new Date()));
    }
  }

  return (
    <>
      <Button outline type="button" onClick={() => setIsOpen(true)}>
        {props.children}
      </Button>
      <Dialog size='md' open={isOpen} onClose={handleClose}>
        <DialogTitle>Record Payment</DialogTitle>
        <DialogDescription>
          Record a payment for {invoice.invoiceNumber}
        </DialogDescription>
        <form onSubmit={handleSave}>
          <DialogBody>
            <FieldGroup>
              <Field>
                <Label>Payment Method</Label>
                <RadioGroup 
                  value={paymentMethod} 
                  onChange={handlePaymentMethodChange}
                >
                  <RadioField>
                    <Radio value="cash" />
                    <Label>Cash/Check/Other</Label>
                  </RadioField>
                  {family?.stripePaymentMethodName && (
                    <RadioField>
                      <Radio value="card" />
                      <Label>Card ({family.stripePaymentMethodName})</Label>
                    </RadioField>
                  )}
                </RadioGroup>
              </Field>

              <Field>
                <Label>Payment Date</Label>
                <Input 
                  type="date" 
                  value={paymentDate} 
                  onChange={(e) => setPaymentDate(e.target.value)}
                  invalid={showErrors && !!errors.paymentDate}
                  disabled={paymentMethod === 'card'}
                />
                {showErrors && errors.paymentDate && <ErrorMessage>{errors.paymentDate}</ErrorMessage>}
              </Field>

              <Field>
                <Label>Amount ($)</Label>
                <Input 
                  type="number"
                  step="0.01"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  invalid={showErrors && !!errors.amount}
                />
                {showErrors && errors.amount && <ErrorMessage>{errors.amount}</ErrorMessage>}
              </Field>
            </FieldGroup>
          </DialogBody>
          <DialogActions>
            <Button plain onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" color="sprout" disabled={loading}>
              {paymentMethod === 'card' ? 'Process Payment' : 'Record Payment'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Toaster />
    </>
  )
}
