import { useState, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import Card from "../catalyst/card";
import { Text } from "../catalyst/text";
import { Button } from "../catalyst/button";
import { PencilIcon, PlusIcon, ReceiptRefundIcon } from "@heroicons/react/24/outline";
import { Switch } from "../catalyst/switch";
import ModifyInvoice from "../../modals/ModifyInvoice/ModifyInvoice";
import CreateInvoice from "../../modals/CreateInvoice/CreateInvoice";
import CreatePaymentMethod from "../../modals/CreatePaymentMethodModal/CreatePaymentMethodModal";
import CreateRefund from "../../modals/CreateRefund/CreateRefundModal";
import VoidInvoice from "../../modals/VoidInvoice/VoidInvoice";
import {
  DescriptionList,
  DescriptionTerm,
  DescriptionDetails,
} from "../catalyst/description-list";
import {
  CreditCardIcon,
  XCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import CreatePayment from "../../modals/CreatePayment/CreatePayment";

const GET_INVOICES = gql`
  query GetInvoices($studentId: ID!) {
    invoicesByStudent(student_Id: $studentId) {
      edges {
        node {
          uuid
          invoiceNumber
          dueDate
          invoiceDate
          paid
          total
          amountPaid
          voided
          lineItems {
            id
            description
            quantity
            unitPrice
          }
          payments {
            id
            date
            amount
            paymentMethodType
            paymentMethodName
            refunds {
              amount
            }
          }
          refunds {
            amount
            id
            date
            reason
          }
        }
      }
    }
  }
`;

const GET_FAMILY = gql`
  query GetFamily($studentId: ID!) {
    familyByStudent(student: $studentId) {
      id
      autoPayEnabled
      name
      stripePaymentMethodName
      stripePaymentMethodId
      stripeCustomerId
      uuid
    }
  }
`;

const UPDATE_FAMILY = gql`
  mutation UpdateFamily($familyId: ID!, $autoPayEnabled: Boolean!) {
    updateFamily(input: { id: $familyId, autoPayEnabled: $autoPayEnabled }) {
      family {
        id
        autoPayEnabled
      }
    }
  }
`;

function StudentBilling({ student }) {
  const [invoices, setInvoices] = useState([]);
  const [family, setFamily] = useState(null);

  useEffect(() => {
    invoicesRefetch;
  }, []);

  const {
    data: invoicesData,
    loading: invoicesLoading,
    refetch: invoicesRefetch,
  } = useQuery(GET_INVOICES, {
    variables: { studentId: student.uuid },
  });

  const {
    data: familyData,
    loading: familyLoading,
    refetch: familyRefetch,
  } = useQuery(GET_FAMILY, {
    variables: { studentId: student.uuid },
  });

  const [updateFamily] = useMutation(UPDATE_FAMILY);

  useEffect(() => {
    if (invoicesData && !invoicesLoading) {
      setInvoices(
        invoicesData.invoicesByStudent.edges.map((edge) => edge.node)
      );
    }
  }, [invoicesData, invoicesLoading]);

  useEffect(() => {
    if (familyData && !familyLoading) {
      setFamily(familyData.familyByStudent);
    }
  }, [familyData, familyLoading]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const handleAutoPayToggle = async (autoPayEnabled) => {
    if (family) {
      await updateFamily({
        variables: { familyId: family.uuid, autoPayEnabled },
      });
      familyRefetch();
    }
  };

  const InvoiceCard = ({ invoice }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const totalRefunds = invoice.refunds?.reduce((sum, refund) => Number(sum) + Number(refund.amount || 0), 0) || 0;
    const remainingBalance = Number(invoice.total);
    const isPaid = remainingBalance <= 0;

    return (
      <Card className="mb-4">
        <Card.Header className={`${!isExpanded && "border-b-0"}`}>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <Button outline onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? (
                  <ChevronUpIcon className="h-5 w-5 text-gray-400" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                )}
              </Button>
              <div>
                <div className="flex items-center gap-2">
                  <Text className="font-medium text-xl">
                    Invoice #{invoice.invoiceNumber}
                  </Text>
                  <span
                    className={`inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium ${
                      invoice.voided
                        ? "bg-gray-100 text-gray-600"
                        : invoice.paid
                        ? "bg-green-50 text-green-600"
                        : "bg-yellow-50 text-yellow-600"
                    }`}
                  >
                    {invoice.voided ? "Void" : invoice.paid ? "Paid" : "Unpaid"}
                  </span>
                </div>
                <Text className="text-sm text-gray-500">
                  Due {formatDate(invoice.dueDate)}
                </Text>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="text-left">
                <Text className="text-sm text-gray-500">Balance</Text>
                <Text
                  className={`text-lg font-medium ${
                    invoice.voided 
                      ? "text-gray-600"
                      : isPaid 
                      ? "text-green-600" 
                      : ""
                  }`}
                >
                  {invoice.voided ? "VOID" : formatCurrency(remainingBalance)}
                </Text>
              </div>
              {!invoice.paid && !invoice.voided && (
                <>
                  <CreatePayment
                    invoice={invoice}
                    family={family}
                    refetch={invoicesRefetch}
                  >
                    <CreditCardIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                  </CreatePayment>
                  <ModifyInvoice invoice={invoice} refetch={invoicesRefetch}>
                    <PencilIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                  </ModifyInvoice>
                  <VoidInvoice invoice={invoice} refetch={invoicesRefetch}>
                    <XCircleIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                  </VoidInvoice>
                </>
              )}
            </div>
          </div>
        </Card.Header>

        {isExpanded && (
          <Card.Body>
            <DescriptionList>
              <DescriptionTerm>Invoice Date</DescriptionTerm>
              <DescriptionDetails>
                <Text>{formatDate(invoice.invoiceDate)}</Text>
              </DescriptionDetails>

              <DescriptionTerm>Due Date</DescriptionTerm>
              <DescriptionDetails>
                <Text>{formatDate(invoice.dueDate)}</Text>
              </DescriptionDetails>
            </DescriptionList>

            <div className="mt-4">
              <Text className="font-medium mb-2">Line Items</Text>
              <div className="border rounded-lg divide-y">
                {invoice.lineItems.map((item) => (
                  <div
                    key={item.id}
                    className="p-3 flex justify-between items-center"
                  >
                    <div>
                      <Text className="font-medium">{item.description}</Text>
                      <Text className="text-sm text-gray-500">
                        Quantity: {item.quantity} ×{" "}
                        {formatCurrency(item.unitPrice)}
                      </Text>
                    </div>
                    <Text className="font-medium">
                      {formatCurrency(item.quantity * item.unitPrice)}
                    </Text>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-4">
              <Text className="font-medium mb-2">Payments</Text>
              {invoice.payments && invoice.payments.length > 0 ? (
                <div className="border rounded-lg divide-y">
                  {invoice.payments.map((payment) => {
                    const totalRefundedForPayment = payment.refunds?.reduce((sum, refund) => sum + refund.amount, 0) || 0;
                    const remainingAmount = payment.amount - totalRefundedForPayment;
                    const canBeRefunded = remainingAmount > 0;
                    const refundStatus = totalRefundedForPayment > 0 
                      ? totalRefundedForPayment === payment.amount 
                        ? "Full Refund" 
                        : "Partial Refund" 
                      : null;

                    return (
                      <div
                        key={payment.id}
                        className="p-3 flex justify-between items-center"
                      >
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="font-medium">
                              {payment.paymentMethodType} - {payment.paymentMethodName}
                            </Text>
                            {refundStatus && (
                              <span
                                className={`inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium ${
                                  refundStatus === "Full Refund"
                                    ? "bg-red-50 text-red-600"
                                    : "bg-yellow-50 text-yellow-600"
                                }`}
                              >
                                {refundStatus}
                              </span>
                            )}
                          </div>
                          <div className="flex flex-col">
                            <Text className="text-sm text-gray-500">
                              {formatDate(payment.date)}
                            </Text>
                            {totalRefundedForPayment > 0 && (
                              <Text className="text-xs text-red-600">
                                Refunded: {formatCurrency(totalRefundedForPayment)}
                              </Text>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center gap-4">
                          <div className="text-right">
                            <Text className="font-medium text-green-600">
                              -{formatCurrency(payment.amount)}
                            </Text>
                            {totalRefundedForPayment > 0 && (
                              <Text className="text-xs text-red-600">
                                Net: {formatCurrency(remainingAmount)}
                              </Text>
                            )}
                          </div>
                          {canBeRefunded && (
                            <CreateRefund
                              invoice={invoice}
                              payment={payment}
                              family={family}
                              refetch={invoicesRefetch}
                              remainingAmount={remainingAmount}
                            >
                              <ReceiptRefundIcon className="h-5 w-5 text-red-600" />
                            </CreateRefund>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-center py-4 border rounded-lg text-gray-500">
                  No payments recorded
                </div>
              )}
            </div>

            <div className="mt-4">
              <Text className="font-medium mb-2">Refunds</Text>
              {invoice.refunds && invoice.refunds.length > 0 ? (
                <div className="border rounded-lg divide-y">
                  {invoice.refunds.map((refund) => (
                    <div
                      key={refund.id}
                      className="p-3 flex justify-between items-center"
                    >
                      <div>
                        <Text className="font-medium">
                          Refund - {refund.reason}
                        </Text>
                        <Text className="text-sm text-gray-500">
                          {formatDate(refund.date)}
                        </Text>
                      </div>
                      <Text className="font-medium text-red-600">
                        +{formatCurrency(refund.amount)}
                      </Text>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-4 border rounded-lg text-gray-500">
                  No refunds issued
                </div>
              )}
            </div>

            <div className="mt-6 border-t pt-4">
              <div className="space-y-2">
                <div className="flex justify-between">
                  <Text className="font-medium">Invoice Total</Text>
                  <Text>{formatCurrency(invoice.total)}</Text>
                </div>
                <div className="flex justify-between text-green-600">
                  <Text className="font-medium">Total Payments</Text>
                  <Text>-{formatCurrency(invoice.amountPaid)}</Text>
                </div>
                {totalRefunds > 0 && (
                  <div className="flex justify-between text-red-600">
                    <Text className="font-medium">Total Refunds</Text>
                    <Text>+{formatCurrency(totalRefunds)}</Text>
                  </div>
                )}
                <div className="flex justify-between pt-2 border-t">
                  <Text className="font-medium">Remaining Balance</Text>
                  <Text
                    className={`font-bold ${
                      remainingBalance === 0 ? "text-green-600" : "text-red-600"
                    }`}
                  >
                    {formatCurrency(remainingBalance)}
                  </Text>
                </div>
              </div>
            </div>
          </Card.Body>
        )}
      </Card>
    );
  };

  if (invoicesLoading || familyLoading) {
    return (
      <Card>
        <Card.Body>
          <div className="text-center py-4">Loading invoices...</div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      {/* New Payment Methods Card */}
      <Card>
        <Card.Header>
          <Card.Title>Payment Methods</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="flex items-center justify-between">
            <div className="flex items-start gap-x-6">
              {family?.stripePaymentMethodName ? (
                <div className="flex gap-x-4">
                  <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-gray-50">
                    <CreditCardIcon
                      className="h-6 w-6 text-gray-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <Text className="font-medium">
                      {family.stripePaymentMethodName}
                    </Text>
                    <Text className="text-sm text-gray-500">
                      Default Payment Method
                    </Text>
                  </div>
                </div>
              ) : (
                <div className="flex gap-x-4">
                  <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-gray-50">
                    <CreditCardIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <Text className="font-medium text-gray-500">
                      No payment method
                    </Text>
                    <Text className="text-sm text-gray-500">
                      Add a payment method to enable auto-pay
                    </Text>
                  </div>
                </div>
              )}
            </div>

            <div className="flex items-center gap-x-4">
              <CreatePaymentMethod
                familyId={family?.uuid}
                buttonText={family?.stripePaymentMethodName ? "Change" : "Add"}
              />
              {family?.stripePaymentMethodName && (
                <div className="flex items-center gap-x-2">
                  <Text className="text-sm">Auto-Pay</Text>
                  <Switch
                    checked={family?.autoPayEnabled}
                    onChange={() => {
                      handleAutoPayToggle(!family?.autoPayEnabled);
                    }}
                    color="sprout"
                  />
                </div>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Existing Billing History Card */}
      <Card>
        <Card.Header className="flex justify-between items-center">
          <Card.Title>Invoices</Card.Title>
          <CreateInvoice student={student} refetch={invoicesRefetch}>
            <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> Create
            Invoice
          </CreateInvoice>
        </Card.Header>
        <Card.Body>
          {invoices.length > 0 ? (
            invoices
              .sort((a, b) => {
                // Sort by due date first
                const dateA = new Date(a.dueDate);
                const dateB = new Date(b.dueDate);

                // Check if payment status is different
                const isAPaid = a.paid;
                const isBPaid = b.paid;

                if (isAPaid !== isBPaid) {
                  // Unpaid invoices come first
                  return isAPaid ? 1 : -1;
                }

                // If payment status is the same, sort by date
                return dateB - dateA;
              })
              .map((invoice) => (
                <InvoiceCard key={invoice.uuid} invoice={invoice} />
              ))
          ) : (
            <div className="text-center py-12">
              <div className="mx-auto h-12 w-12 text-gray-400">
                <CreditCardIcon className="h-12 w-12" />
              </div>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No invoices
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                No billing history available for this student.
              </p>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default StudentBilling;
