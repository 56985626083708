import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, DialogTitle, DialogDescription, DialogActions, DialogBody } from '../../components/catalyst/dialog';
import { Button } from '../../components/catalyst/button';
import { gql } from 'graphql-tag';
import { Field, FieldGroup, Label, ErrorMessage } from '../../components/catalyst/fieldset';
import { Input } from '../../components/catalyst/input';
import { Select } from '../../components/catalyst/select';
import { PlusIcon } from '@heroicons/react/24/outline';

const CREATE_ACTIVITY = gql`
  mutation CreateActivity($input: CreateActivityInput!) {
    createActivity(input: $input) {
      activity {
        id
        type
        date
        content
        image
      }
    }
  }
`;

const formatDateTimeForInput = (isoString) => {
  if (!isoString) return '';
  const date = new Date(isoString);
  return date.toLocaleString('sv-SE', { 
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: undefined
  }).replace(' ', 'T');
};

const ACTIVITY_LABELS = {
    SC: "Sunscreen",
    PO: "Post",
    IC: "Incident",
    AB: "Absence",
    IN: "Injury",
    DC: "Diaper Change",
    PT: "Potty",
    SN: "Snack",
    DK: "Drink",
    ML: "Meal",
    NT: "Note",
    NP: "Nap",
  };

export default function CreateActivity({student, refetch, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const [activityType, setActivityType] = useState('');
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [graphqlError, setGraphqlError] = useState(null);
  const [date, setDate] = useState(formatDateTimeForInput(new Date().toISOString()));
  const [content, setContent] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const [createActivity, { loading }] = useMutation(CREATE_ACTIVITY, {
    onCompleted: async () => {
      await refetch();
      handleClose();
    },
    onError: (error) => {
      setGraphqlError(error.message);
      setShowErrors(true);
    },
  });

  const handleClose = () => {
    setIsOpen(false);
    setActivityType('');
    setDate(formatDateTimeForInput(new Date().toISOString()));
    setContent('');
    setImageFile(null);
    if (previewImage) {
      URL.revokeObjectURL(previewImage);
      setPreviewImage(null);
    }
    setErrors({});
    setShowErrors(false);
    setGraphqlError(null);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!activityType) newErrors.activityType = 'Activity type is required';
    if (!date) newErrors.date = 'Date is required';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const newErrors = validateForm();
    setErrors(newErrors);
    setShowErrors(true);

    if (Object.keys(newErrors).length === 0) {
      createActivity({
        variables: {
          input: {
            student: student.uuid,
            type: activityType,
            date: new Date(date).toISOString(),
            content,
            image: imageFile,
          },
        },
      });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const previewUrl = URL.createObjectURL(file);
      setPreviewImage(previewUrl);
    }
  };

  useEffect(() => {
    return () => {
      if (previewImage) {
        URL.revokeObjectURL(previewImage);
      }
    };
  }, [previewImage]);


  return (
    <>
      <Button outline type="button" onClick={() => setIsOpen(true)}>
        <PlusIcon className="w-4 h-4" /> Create Activity
      </Button>
      <Dialog 
        size="lg" 
        open={isOpen} 
        onClose={() => setIsOpen(false)} 
      >
        <DialogTitle>Create an Activity</DialogTitle>
        <DialogDescription>
          Create a new activity.
        </DialogDescription>
        <form onSubmit={handleSubmit}>
          <DialogBody>
            <FieldGroup>
              <div className="grid grid-cols-2 gap-4">
              <Field>
                <Label>Activity Type</Label>
                <Select 
                  value={activityType} 
                  onChange={(e) => setActivityType(e.target.value)}
                  invalid={showErrors && !!errors.activityType}
                >
                  <option value="" disabled>Select an Activity Type</option>
                  {Object.keys(ACTIVITY_LABELS).map((type) => (
                    <option key={type} value={type}>
                      {ACTIVITY_LABELS[type]}
                    </option>
                  ))}
                </Select>
                {showErrors && errors.activityType && (
                  <ErrorMessage>{errors.activityType}</ErrorMessage>
                )}
              </Field>

              <Field>
                <Label>Date and Time</Label>
                <Input
                  type="datetime-local"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  invalid={showErrors && !!errors.date}
                />
                {showErrors && errors.date && (
                  <ErrorMessage>{errors.date}</ErrorMessage>
                )}
              </Field>
              </div>
              <Field>
                <Label>Content</Label>
                <Input
                  type="text"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  placeholder="Activity details..."
                />
              </Field>

              <Field>
                <Label>Image</Label>
                {(previewImage || imageFile) && (
                  <div className="mb-4 mt-4">
                    <div className="aspect-video w-full rounded-lg overflow-hidden relative">
                      <div 
                        className="absolute inset-0 bg-cover bg-center blur-xl scale-110 opacity-50"
                        style={{ backgroundImage: `url(${previewImage || imageFile})` }}
                      />
                      <div className="relative h-full flex items-center justify-center">
                        <img
                          src={previewImage || imageFile}
                          alt={previewImage ? "Preview" : "Current activity"}
                          className="max-h-full max-w-full object-contain"
                        />
                      </div>
                    </div>
                    <p className="text-sm text-gray-500 mt-1">
                      {previewImage ? 'New image' : 'Current image'}
                    </p>
                  </div>
                )}
                <div className="flex flex-col space-y-2 mt-4">
                  <Button
                    as="label"
                    color="sprout"
                    htmlFor="file-upload"
                    className="cursor-pointer"
                  >
                    {(previewImage || imageFile) ? 'Change Image' : 'Upload Image'}
                  </Button>
                  <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
              </Field>
            </FieldGroup>
          </DialogBody>
          <DialogActions className="flex justify-end">
            <Button plain onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" color="sprout" disabled={loading}>
              Update Activity
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
} 