import { Button } from '../catalyst/button'
import { AlertTitle, AlertDescription, AlertActions, AlertBody, Alert } from '../catalyst/alert'
import { Field, FieldGroup, Label, ErrorMessage } from '../catalyst/fieldset'
import { Input } from '../catalyst/input'
import { Select } from '../catalyst/select'
import { useState } from 'react'
import { useMutation, gql } from '@apollo/client'

const CREATE_PROGRAM = gql`
  mutation CreateProgram($input: CreateProgramInput!) {
    createProgram(input: $input) {
      program {
        id
      }
    }
  }
`

export default function CreateProgramModal({buttonText, refetch}) {
  let [isOpen, setIsOpen] = useState(false)
  let [name, setName] = useState('')
  let [rate, setRate] = useState('')
  let [rateFrequency, setRateFrequency] = useState('')
  let [daysOfWeek, setDaysOfWeek] = useState([])
  let [maxEnrollments, setMaxEnrollments] = useState('')
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [createProgram, { loading }] = useMutation(CREATE_PROGRAM, {
    onCompleted: () => {
      setIsOpen(false)
      refetch()
    }
  })

  const validateForm = () => {
    const newErrors = {}
    if (!name) newErrors.name = 'Name is required'
    if (!rate || rate <= 0) newErrors.rate = 'Valid rate is required'
    if (!rateFrequency) newErrors.rateFrequency = 'Billing frequency is required'
    if (daysOfWeek.length === 0) newErrors.daysOfWeek = 'At least one day of the week is required'
    return newErrors
  }

  const handleCreateProgram = (e) => {
    e.preventDefault()
    
    const newErrors = validateForm()
    setErrors(newErrors)
    setShowErrors(true)

    if (Object.keys(newErrors).length === 0) {
      createProgram({ 
        variables: { 
          input: { 
            name, 
            defaultRate: Number(rate), 
            defaultRateFrequency: rateFrequency,
            daysOfWeek,
            maxEnrollments: Number(maxEnrollments)
          } 
        } 
      })
    }
  }

  return (
        <>
      <Button outline type="button" onClick={() => setIsOpen(true)}>
        {buttonText}
      </Button>
      <Alert open={isOpen} onClose={setIsOpen}>
        <AlertTitle>Create Program</AlertTitle>
        <AlertDescription>
          Create a new program.
        </AlertDescription>
        <form onSubmit={handleCreateProgram}>
          <AlertBody>
            <FieldGroup>
            <div className="grid grid-cols-2 gap-4">
              <Field>
                <Label>Name</Label>
                <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </Field>
                <Field>
                  <Label>Max Students</Label>
                  <Input type="number" value={maxEnrollments} onChange={(e) => setMaxEnrollments(e.target.value)} />
                </Field>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Field>
                  <Label className="flex items-center gap-1">
                    Default Rate ($)
                  </Label>
                  <Input 
                    type="number" 
                    value={rate} 
                    onChange={(e) => setRate(e.target.value)}
                    invalid={showErrors && !!errors.rate}
                  />
                  {showErrors && errors.rate && <ErrorMessage>{errors.rate}</ErrorMessage>}
                </Field>
                <Field>
                  <Label>Default Billing Frequency</Label>
                  <Select 
                    value={rateFrequency} 
                    onChange={(e) => setRateFrequency(e.target.value)}
                    invalid={showErrors && !!errors.rateFrequency}
                  >
                    <option value="" disabled>Select Frequency</option>
                    <option value="One Time">One Time</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </Select>
                  {showErrors && errors.rateFrequency && <ErrorMessage>{errors.rateFrequency}</ErrorMessage>}
                </Field>
              </div>
              <Field>
                <Label>Days of week</Label>
                <div className="mt-2 flex justify-between gap-2">
                  {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => {
                    const fullDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][index];
                    return (
                      <label
                        key={day}
                        className="group relative cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          value={fullDay}
                          checked={daysOfWeek.includes(fullDay)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setDaysOfWeek([...daysOfWeek, fullDay])
                            } else {
                              setDaysOfWeek(daysOfWeek.filter(day => day !== fullDay))
                            }
                          }}
                          className="peer absolute opacity-0"
                        />
                        <div className="flex h-9 w-12 items-center justify-center rounded-lg border border-gray-200 text-sm font-medium text-gray-700 transition-all hover:bg-gray-50 peer-checked:border-sprout-600 peer-checked:bg-sprout-50 peer-checked:text-sprout-600">
                          {day}
                        </div>
                      </label>
                    );
                  })}
                </div>
                {showErrors && errors.daysOfWeek && <ErrorMessage className={'mt-2'}>{errors.daysOfWeek}</ErrorMessage>}
              </Field>
            </FieldGroup>
          </AlertBody>
          <AlertActions>
            <Button type="button" plain onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button type="submit" color="sprout" disabled={loading}>
              Save
            </Button>
          </AlertActions>
        </form>
      </Alert>
    </>
  )
}