import { useState, useEffect } from "react";
import {
  Pagination,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
} from "../../components/catalyst/pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/catalyst/table";
import { gql, useQuery } from "@apollo/client";
import { Select } from "../../components/catalyst/select";
import { useLocation } from "wouter";
import { CreateStudentModal } from "../../modals/CreateStudentModal/CreateStudentModal";
import SkeletonTable from "../../components/skeleton-table";


const STUDENTS_QUERY = gql`
  query GetStudents($after: String, $first: Int!, $archived: Boolean) {
    students(after: $after, first: $first, archived: $archived) {
      edges {
        node {
          uuid
          firstName
          lastName
          dateOfBirth
          gender
          enrolled
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;


function StudentsPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('active');
  const studentsPerPage = 25;

  const [location, navigate] = useLocation();

  const { data, loading, fetchMore, refetch } = useQuery(STUDENTS_QUERY, {
    variables: {
      after: null,
      first: studentsPerPage,
      archived: filter === 'archived'
    },
  });

  const students = data?.students?.edges || [];
  const pageInfo = data?.students?.pageInfo;

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    refetch()
  }, [filter]);

  useEffect(() => {
    refetch()
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage > currentPage && pageInfo.hasNextPage) {
      fetchMore({
        variables: { after: pageInfo.endCursor, first: studentsPerPage },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            students: {
              ...fetchMoreResult.students,
              edges: [
                ...prev.students.edges,
                ...fetchMoreResult.students.edges,
              ],
            },
          };
        },
      });
    }
    setCurrentPage(newPage);
  };

  const displayedStudents = students.slice(
    (currentPage - 1) * studentsPerPage,
    currentPage * studentsPerPage
  );

  const totalPages = Math.max(
    Math.ceil(students.length / studentsPerPage),
    currentPage + (pageInfo?.hasNextPage ? 1 : 0)
  );

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-base font-semibold">Students</h1>
        <div className="flex items-center gap-2">
          <Select
            value={filter}
            onChange={handleFilterChange}
            className="w-48">
            <option value="active">Active</option>
            <option value="archived">Archived</option>
          </Select>
          <CreateStudentModal>
            Create Student
          </CreateStudentModal>
        </div>
      </div>
      {loading && displayedStudents.length === 0 ? (
        <SkeletonTable columns={5} rows={25} />
      ) : displayedStudents.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>First Name</TableHeader>
                <TableHeader>Last Name</TableHeader>
                <TableHeader>Date of Birth</TableHeader>
                <TableHeader>Gender</TableHeader>
                <TableHeader>Enrolled</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedStudents.map((student) => (
                <TableRow className="cursor-pointer" key={student.node.uuid} onClick={() => navigate(`/students/${student.node.uuid}`)}>
                  <TableCell className="font-medium">
                    {student.node.firstName}
                  </TableCell>
                  <TableCell>{student.node.lastName}</TableCell>
                  <TableCell>{student.node.dateOfBirth}</TableCell>
                  <TableCell>{student.node.gender}</TableCell>
                  <TableCell>{student.node.enrolled ? "Yes" : "No"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination className="mt-6">
            <PaginationPrevious
              onClick={(e) => {
                e.preventDefault();
                handlePageChange(currentPage - 1);
              }}
              disabled={currentPage === 1}
            />
            <PaginationList>
              {[...Array(totalPages)].map((_, index) => (
                <PaginationPage
                  key={index + 1}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePageChange(index + 1);
                  }}
                  current={currentPage === index + 1}
                >
                  {index + 1}
                </PaginationPage>
              ))}
            </PaginationList>
            <PaginationNext
              onClick={(e) => {
                e.preventDefault();
                handlePageChange(currentPage + 1);
              }}
              disabled={!pageInfo?.hasNextPage && currentPage === totalPages}
            />
          </Pagination>
        </>
      ) : (
        <div className="text-center py-12">
          <p className="text-xl font-semibold text-gray-700">No students found</p>
          <p className="text-gray-500 mt-2">
            {filter === 'active'
              ? "There are no active students at the moment."
              : "There are no archived students at the moment."}
          </p>
        </div>
      )}
    </>
  );
}

export default StudentsPage;
