import { useState, useEffect, useCallback } from 'react';
import { useUser } from '../../hooks/useUser';
import { ErrorMessage, Field, FieldGroup, Fieldset, Label, Legend, Description } from '../../components/catalyst/fieldset';
import { Input } from '../../components/catalyst/input';
import { Text } from '../../components/catalyst/text';
import { Heading } from '../../components/catalyst/heading';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Button } from '../../components/catalyst/button';
import GoogleSearch from '../../components/address-input';
import { useMutation, gql } from '@apollo/client';
import debounce from 'lodash/debounce';
import toast, { Toaster } from 'react-hot-toast';
import { API_BASE_URL } from '../../config';
import { GetCSRFToken } from '../../utils/getCSRF';
import SpinnerWhite from '../../components/spinner-white';


const UPDATE_USER = gql`
  mutation ModifyUser($profilePicture: Upload, $lastName: String, $id: ID!, $firstName: String, $email: String) {
    modifyUser(
      input: { id: $id, email: $email, firstName: $firstName, lastName: $lastName, profilePicture: $profilePicture }
    ) {
      user {
        id
      }
    }
  }
`;

const UPDATE_ORG = gql`
  mutation ModifyOrg($id: ID!, $email: String, $address: JSONString, $logo: Upload, $name: String, $phoneNumber: String, $timezone: String) {
    modifyOrganization(
      input: {id: $id, email: $email, address: $address, logo: $logo, name: $name, phoneNumber: $phoneNumber, timezone: $timezone}
    ) {
      organization {
        id
      }
    }
  }
`;

const UPDATE_ORG_SETUP_STEP = gql`
  mutation UpdateOrgSetupStep($id: ID!, $accountSetupCurrentStep: Int!) {
    modifyOrganization(
      input: {id: $id, accountSetupCurrentStep: $accountSetupCurrentStep}
    ) {
      organization {
        id
      }
    }
  }
`;

const COMPLETE_SETUP = gql`
  mutation CompleteSetup($id: ID!, $accountSetupComplete: Boolean!) {
    modifyOrganization(
      input: {id: $id, accountSetupComplete: $accountSetupComplete}
    ) {
      organization {
        id
      }
    }
  }
`;

const steps = [
  { name: 'Account', description: 'Tell us a bit about yourself.', href: '#' },
  { name: 'Business', description: 'Tell us some details about your business.'},
  { name: 'Payments', description: 'Get setup with Stripe to accept payments.'},
  { name: 'Preferences', description: 'Set your preferences for billing, notifications, and more.'},
  { name: 'Finish Up', description: 'Choose the right Sprout plan for you to get started.' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function OnboardingPage() {
  const { user, refetch } = useUser();
  const [profileImageText, setProfileImageText] = useState('No file chosen');
  const [profileImage, setProfileImage] = useState();
  const [logoImage, setLogoImage] = useState();
  const [logoImageText, setLogoImageText] = useState('No file chosen');
  const [businessAddress, setBusinessAddress] = useState(JSON.parse(user?.organization.address) || null)
  const [currentStep, setCurrentStep] = useState(0);
  const [localStep, setLocalStep] = useState(0);
  const [loading, setLoading] = useState(false)
  const [userFormDirty, setUserFormDirty] = useState(false);
  const [userForm, setUserForm] = useState({
    id: user?.id || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
  });
  const [orgFormDirty, setOrgFormDirty] = useState(false);
  const [orgForm, setOrgForm] = useState({
    id: user?.organization?.uuid || '',
    name: user?.organization?.name || '',
    email: user?.organization?.email || '',
    phoneNumber: user?.organization?.phoneNumber || '',
    timezone: user?.organization?.timezone || '',
    logo: user?.organization?.logo || '',
  });
  const [previewImage, setPreviewImage] = useState(user?.avatar || null);
  const [isSaving, setIsSaving] = useState(false);
  const [stepValidation, setStepValidation] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false
  });
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [isPolling, setIsPolling] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      refetch();
      toast.dismiss('savingChanges');
      toast.success('Changes saved!');
      setIsSaving(false);
    },
    onError: () => {
      toast.dismiss('savingChanges');
      toast.error('Error saving changes.');
      setIsSaving(false);
    },
  });

  useEffect(() => {
    setOrgFormDirty(true)
  }, [businessAddress]);

  useEffect(() => {
    console.log(user)
  }, [user]);

  useEffect(() => {
    console.log(businessAddress)
  }, [businessAddress]);

  const debouncedUpdateUser = useCallback(
    debounce(() => {
      if (user?.id && userFormDirty) {
        setIsSaving(true);
        toast.loading('Saving changes...', { id: 'savingChanges' });
        updateUser({
          variables: {
            id: user?.id,
            firstName: userForm.firstName || '',
            lastName: userForm.lastName || '',
            profilePicture: profileImage || null,
          },
        });
      }
    }, 500),
    [user?.id, userForm.firstName, userForm.lastName, profileImage, updateUser, userFormDirty]
  );


  useEffect(() => {
    console.log(orgForm)
  }, [orgForm]);

  useEffect(() => {
    if (userFormDirty) {
      debouncedUpdateUser();
    }
    return debouncedUpdateUser.cancel;
  }, [debouncedUpdateUser, userFormDirty]);

  const validateForm = useCallback(() => {
    const newErrors = {};
    
    if (currentStep === 0) {
      if (!userForm.firstName.trim()) newErrors.firstName = 'First Name is required';
      if (!userForm.lastName.trim()) newErrors.lastName = 'Last Name is required';
    } else if (currentStep === 1) {
      if (!orgForm.name.trim()) newErrors.name = 'Organization Name is required';
      if (!orgForm.email.trim()) newErrors.email = 'Organization Email is required';
      if (!businessAddress) newErrors.address = 'Business Address is required';
    }
    console.log('New errors:', newErrors);
    return newErrors;
  }, [currentStep, userForm, orgForm, businessAddress]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserForm(prev => ({ ...prev, [name]: value }));
    setUserFormDirty(true);
    if (showErrors) {
      const newErrors = validateForm();
      setErrors(newErrors);
    }
  };

  const [updateOrg] = useMutation(UPDATE_ORG, {
    onCompleted: () => {
      refetch();
      toast.dismiss('savingChanges');
      toast.success('Changes saved!');
      setIsSaving(false);
    },
    onError: () => {
      toast.dismiss('savingChanges');
      toast.error('Error saving changes.');
      setIsSaving(false);
    },
  });

  const debouncedUpdateOrg = useCallback(
    debounce(() => {
      if (user?.id && orgFormDirty) {
        setIsSaving(true);
        toast.loading('Saving changes...', { id: 'savingChanges' });
        updateOrg({
          variables: {
            id: user?.organization.uuid,
            email: orgForm.email,
            address: JSON.stringify(businessAddress),
            logo: logoImage,
            name: orgForm.name,
            phoneNumber: orgForm.phoneNumber,
            timezone: orgForm.timezone
          },
        });
      }
    }, 500),
    [user?.organization?.uuid, orgForm, businessAddress, logoImage, orgFormDirty, updateOrg]
  );

  useEffect(() => {
    if (orgFormDirty) {
      debouncedUpdateOrg();
    }
    return debouncedUpdateOrg.cancel;
  }, [debouncedUpdateOrg, orgFormDirty]);

  const handleOrgChange = (e) => {
    const { name, value } = e.target;
    setOrgForm(prev => ({ ...prev, [name]: value }));
    setOrgFormDirty(true);
    if (showErrors) {
      const newErrors = validateForm();
      setErrors(newErrors);
    }
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setUserFormDirty(true);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoImage(file);
      setOrgFormDirty(true);
    }
  };

  const handleStripeSetup = async () => {
    setLoading(true);
    setIsPolling(true);
    const accessToken = localStorage.getItem('accessToken');

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/connect/link`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        setLoading(false);
        setIsPolling(false);
        toast.error('There was an error processing this request.');
        throw new Error(`Response status: ${response.status}`);
      } else {
        const data = await response.json();
        if (data.url) {
          window.location.href = data.url;
        } else {
          setLoading(false);
          setIsPolling(false);
          toast.error('There was an error processing this request.');
          throw new Error('URL not found in response data');
        }
      }
    } catch (error) {
      setLoading(false);
      setIsPolling(false);
      toast.error(error.message);
    }
};

  useEffect(() => {
    if (!user?.organization?.stripeOnboardingComplete && user?.organization?.stripeAccountId) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [user?.organization?.stripeOnboardingComplete, user?.organization?.stripeAccountId])


  const [updateOrgSetupStep] = useMutation(UPDATE_ORG_SETUP_STEP, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      console.error('Error updating setup step:', error);
      toast.error('Error updating setup progress.');
    },
  });

  const [updateCompleteSetup] = useMutation(COMPLETE_SETUP, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      console.error('Error completing setup:', error);
      toast.error('Error completing setup.');
    },
  });

  useEffect(() => {
    if (user?.organization?.accountSetupCurrentStep !== undefined) {
      setCurrentStep(user.organization.accountSetupCurrentStep);
      setLocalStep(user.organization.accountSetupCurrentStep);
    }
  }, [user]);

  

  const validateStep = (step) => {
    const errors = {};
    switch(step) {
      case 0:
        if (!userForm.firstName.trim()) errors.firstName = 'First Name is required';
        if (!userForm.lastName.trim()) errors.lastName = 'Last Name is required';
        break;
      case 1:
        if (!orgForm.name.trim()) errors.name = 'Organization Name is required';
        if (!orgForm.email.trim()) errors.email = 'Organization Email is required';
        if (!businessAddress) errors.address = 'Business Address is required';
        break;
      case 2:
        if (!user?.organization?.stripeOnboardingComplete) {
          console.error('Stripe onboarding is not complete:', user?.organization);
          errors.stripeOnboardingComplete = 'Stripe Onboarding is required';
        }
        break;
      case 3:
        break;
      case 4:
        break;
    }
    return errors;
  };

  const handleStepClick = (stepIndex) => {
    if (stepIndex <= localStep) {
      setLocalStep(stepIndex);
      setShowErrors(false);
    } else {
      let isValid = true;
      for (let i = 0; i <= stepIndex; i++) {
        const stepErrors = validateStep(i);
        if (Object.keys(stepErrors).length > 0) {
          setErrors(stepErrors);
          setShowErrors(true);
          setLocalStep(i);
          isValid = false;
          break;
        }
      }
      if (isValid) {
        setLocalStep(stepIndex);
        setShowErrors(false);
        updateOrgSetupStep({
          variables: {
            id: user?.organization?.uuid,
            accountSetupCurrentStep: stepIndex,
          },
        });
      }
    }
  };

  useEffect(() => {
    console.log(errors)
  }, [errors]);

  const handleNextStep = () => {
    const newErrors = validateStep(localStep);
    setErrors(newErrors);
    setShowErrors(true);

    if (Object.keys(newErrors).length === 0) {
      const nextStep = localStep + 1;
      setLocalStep(nextStep);
      setShowErrors(false);
      updateOrgSetupStep({
        variables: {
          id: user?.organization?.uuid,
          accountSetupCurrentStep: nextStep,
        },
      });
    } else {
      console.log('Form validation failed');
    }
  };

  const handleCompleteSetup = () => {
    updateCompleteSetup({
      variables: {
        id: user?.organization?.uuid,
        accountSetupComplete: true,
      },
    });
  };

  const handlePreviousStep = () => {
    const previousStep = localStep - 1;
    setLocalStep(previousStep);
    updateOrgSetupStep({
      variables: {
        id: user?.organization?.uuid,
        accountSetupCurrentStep: previousStep,
      },
    });
  };

  const onboardingSteps = () => {
    return (
      <nav aria-label="Onboarding Progress">
        <ol role="list" className="overflow-hidden">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
              {stepIdx <= localStep ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div aria-hidden="true" className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-sprout-600" />
                  ) : null}
                  <div onClick={() => handleStepClick(stepIdx)} className="group relative flex items-start cursor-pointer">
                    <span className="flex h-9 items-center">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-sprout-600 group-hover:bg-sprout-800">
                        <CheckIcon aria-hidden="true" className="h-5 w-5 text-white" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium">{step.name}</span>
                      <span className="text-sm text-gray-500">{step.description}</span>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div aria-hidden="true" className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" />
                  ) : null}
                  <div onClick={() => handleStepClick(stepIdx)} className="group relative flex items-start cursor-pointer">
                    <span aria-hidden="true" className="flex h-9 items-center">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                        <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium text-gray-500">{step.name}</span>
                      <span className="text-sm text-gray-500">{step.description}</span>
                    </span>
                  </div>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
    )
  }

  const userSetupForm = () => {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <Fieldset>
          <Legend>{steps[currentStep].name}</Legend>
          <Text>{steps[currentStep].description}</Text>
          <FieldGroup>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
              <Field>
                <Label>First Name</Label>
                <Input 
                  name="firstName" 
                  value={userForm.firstName} 
                  onChange={handleUserChange}
                  invalid={showErrors && !!errors.firstName}
                />
                {showErrors && errors.firstName && <ErrorMessage>{errors.firstName}</ErrorMessage>}
              </Field>
              <Field>
                <Label>Last Name</Label>
                <Input 
                  name="lastName" 
                  value={userForm.lastName} 
                  onChange={handleUserChange}
                  invalid={showErrors && !!errors.lastName}
                />
                {showErrors && errors.lastName && <ErrorMessage>{errors.lastName}</ErrorMessage>}
              </Field>
            </div>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
              <Field>
                <Label>Email</Label>
                <Input name="email" value={userForm.email} disabled />
              </Field>
              <Field>
                <Label>Profile Picture</Label>
                <div className="mt-2 flex items-center space-x-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
                  <div className="flex-shrink-0">
                    {previewImage ? (
                      <img
                        src={previewImage}
                        alt="Profile preview"
                        className="w-16 h-16 object-cover rounded-full border-2 border-white shadow-md"
                      />
                    ) : (
                      <div className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center">
                        <svg className="h-8 w-8 text-gray-400" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col space-y-2">
                    <Button
                      as="label"
                      htmlFor="file-upload"
                      color="white"
                      className="cursor-pointer"
                    >
                      {previewImage ? 'Change Picture' : 'Upload Picture'}
                    </Button>
                    <input
                      id="file-upload"
                      type="file"
                      className="hidden"
                      onChange={handleProfileImageChange}
                      accept="image/*"
                    />
                    <Description className="text-sm text-gray-500">
                      JPG, GIF or PNG
                    </Description>
                  </div>
                </div>
              </Field>
            </div>
          </FieldGroup>
        </Fieldset>
      </form>
    );
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setOrgForm(prevForm => ({ ...prevForm, phoneNumber: formattedPhoneNumber }));
    setOrgFormDirty(true);
  };

  const orgSetupForm = () => {
    return <form onSubmit={(e) => e.preventDefault()}>
      <Fieldset>
        <Legend>{steps[currentStep].name}</Legend>
        <Text>{steps[currentStep].description}</Text>
        <FieldGroup>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
            <Field>
              <Label>Organization Name</Label>
              <Input 
                name="name" 
                value={orgForm.name} 
                onChange={handleOrgChange}
                invalid={showErrors && !!errors.name}
              />
              {showErrors && errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
            </Field>
            <Field>
              <Label>Phone Number</Label>
              <Input
                name="phoneNumber"
                value={orgForm.phoneNumber}
                onChange={handlePhoneChange}
                placeholder="(123) 456-7890"
                maxLength={14}
              />
            </Field>
          </div>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
            <Field>
              <Label>Organization Email</Label>
              <Input 
                name="email" 
                value={orgForm.email} 
                onChange={handleOrgChange}
                invalid={showErrors && !!errors.email}
              />
              {showErrors && errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            </Field>
            <Field>
              <Label>Business Address</Label>
              <GoogleSearch 
                name="address" 
                onChange={(address) => {
                  setBusinessAddress(address);
                  if (showErrors) {
                    const newErrors = validateForm();
                    setErrors(newErrors);
                  }
                }} 
                value={businessAddress}
                invalid={showErrors && !!errors.address}
              />
              {showErrors && errors.address && <ErrorMessage>{errors.address}</ErrorMessage>}
            </Field>
          </div>
          <Field>
            <Label>Logo</Label>
            <div className="mt-2 flex items-center space-x-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
              <div className="flex-shrink-0">
                {logoImage || user?.organization?.logoUrl ? (
                  <img
                    src={logoImage ? URL.createObjectURL(logoImage) : user?.organization?.logoUrl}
                    alt="Organization logo preview"
                    className="w-16 h-16 object-contain rounded-md border-2 border-white shadow-md"
                  />
                ) : (
                  <div className="w-16 h-16 rounded-md bg-gray-200 flex items-center justify-center">
                    <svg className="h-8 w-8 text-gray-400" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M4 4h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm0 2v12h16V6H4zm8 3a2 2 0 110 4 2 2 0 010-4zm0 6a4 4 0 110-8 4 4 0 010 8z" />
                    </svg>
                  </div>
                )}
              </div>
              <div className="flex flex-col space-y-2">
                <Button
                  as="label"
                  htmlFor="logo-upload"
                  color="white"
                  className="cursor-pointer"
                >
                  {logoImage || user?.organization?.logo ? 'Change Logo' : 'Upload Logo'}
                </Button>
                <input
                  id="logo-upload"
                  type="file"
                  className="hidden"
                  onChange={handleLogoImageChange}
                  accept="image/*"
                />
                <Description className="text-sm text-gray-500">
                  JPG, GIF or PNG. Max size of 800K
                </Description>
              </div>
            </div>
          </Field>
        </FieldGroup>
      </Fieldset>
    </form>;
  };

  const paymentsSetupForm = () => {
    return (
      <form onSubmit={null}>
        <Fieldset>
          <Legend>{steps[currentStep].name}</Legend>
          <Text>{steps[currentStep].description}</Text>
          <FieldGroup>
            <div className="bg-gray-50 p-6 rounded-lg border border-gray-200 mb-6">
              <div className="flex items-center mb-4">
                <img src="/stripe.svg" alt="Stripe Logo" className="h-16 w-auto mr-3" />
                <Text className="text-lg font-semibold">Stripe Payments Integration</Text>
              </div>
              <Text className="mb-4">
                Stripe allows you to securely accept payments from your customers. By integrating with Stripe, you can:
              </Text>
              <ul className="list-disc list-inside mb-6 space-y-2 text-gray-700">
                <li className="text-sm">Accept credit card payments</li>
                <li className="text-sm">Manage subscriptions and recurring billing</li>
                <li className="text-sm">Access detailed financial reports</li>
                <li className="text-sm">Ensure compliance with payment industry standards</li>
              </ul>
              <div className="flex items-center justify-between">
                <Text className="text-sm text-gray-500">
                  You'll be redirected to Stripe to complete the setup process.
                </Text>
                {user?.organization.stripeOnboardingComplete ? 
                  <Button color="sprout" disabled={true}>
                    Setup Complete
                  </Button> : 
                  <Button color="sprout" disabled={loading || isPolling} onClick={() => handleStripeSetup()}>
                    {loading || isPolling ? <SpinnerWhite/> : 'Connect with Stripe'}
                  </Button>
                }
              </div>
              {showErrors && errors.stripeOnboardingComplete && (
                <Text className="text-red-500">{errors.stripeOnboardingComplete}</Text>
              )}
            </div>
          </FieldGroup>
        </Fieldset>
        <input type="submit" className="hidden" />
      </form>
    );
  };

  const pollOrganizationStatus = useCallback(async () => {
    if (!user?.organization?.uuid) return;

    try {
      const { data } = await refetch();
      if (data.user.organization.stripeOnboardingComplete) {
        setIsPolling(false);
        toast.success('Stripe onboarding completed successfully!');
      }
    } catch (error) {
      console.error('Error polling organization status:', error);
      setIsPolling(false);
    }
  }, [user?.organization?.uuid, refetch]);

  useEffect(() => {
    let pollInterval;
    if (localStep === 2 && !user?.organization?.stripeOnboardingComplete && isPolling) {
      pollInterval = setInterval(pollOrganizationStatus, 5000); // Poll every 5 seconds
    }

    return () => {
      if (pollInterval) clearInterval(pollInterval);
    };
  }, [localStep, user?.organization?.stripeOnboardingComplete, isPolling, pollOrganizationStatus]);

  return (
    <div className='flex flex-col w-full gap-8'>
      <div className='flex flex-col w-full items-start mb-4'>
        <Heading className={"items-start"}>Get started with Sprout</Heading>
        <Text>Let&apos;s get you set up.</Text>
      </div>
      <div className="flex flex-col md:flex-row justify-between gap-16">
        {onboardingSteps()}
        <div className='w-full'>
          {localStep === 0 && userSetupForm()}
          {localStep === 1 && orgSetupForm()}
          {localStep === 2 && paymentsSetupForm()}

          <div className='flex flex-row justify-between mt-8 w-full'>
            <Button outline disabled={localStep === 0} onClick={handlePreviousStep}>Previous</Button>
            {localStep < steps.length - 1 ? (
              <Button outline onClick={handleNextStep}>
                Next
              </Button>
            ) : (
              <Button color="sprout" onClick={handleCompleteSetup}>
                Finish
              </Button>
            )}
          </div>
        </div>
      </div>
      <Toaster/>
    </div>
  );
}

export default OnboardingPage;
