import { useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { Input } from '../../components/catalyst/input'
import { Button } from '../../components/catalyst/button'
import { Field, FieldGroup, Fieldset, Label } from '../../components/catalyst/fieldset'
import { Text } from '../../components/catalyst/text'
import { Heading } from '../../components/catalyst/heading'
import logoIcon from '../../assets/logo-icon.svg'
import { XCircleIcon } from '@heroicons/react/24/outline'

function AuthenticationPage() {
  const { login, signup } = useAuth()
  const [isLogin, setIsLogin] = useState(true)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(null)
    try {
      if (isLogin) {
        const loginError = await login({ email: email, password: password })
        if (loginError) {
          setError(loginError.message)
        }
      } else {
        const signupError = await signup({ "first_name": firstName, "last_name": lastName, "email": email, "password": password })
        if (signupError) {
          setError(signupError.message)
        }
      }
    } catch (err) {
      console.error('Authentication error:', err)
      setError('An unexpected error occurred during authentication')
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 lg:rounded-lg lg:bg-white lg:p-10 lg:shadow-sm lg:ring-1 lg:ring-zinc-950/5 dark:lg:bg-zinc-900 dark:lg:ring-white/10">
        <div className="flex justify-center mb-6">
          <img src={logoIcon} alt="Sprout Logo" className="h-16 w-auto" />
        </div>
        <form onSubmit={handleSubmit}>
          <Fieldset>
            <Heading level={2} className="text-3xl font-bold text-center mb-2">
              {isLogin ? 'Login' : 'Sign Up'}
            </Heading>
            <Text className="text-center text-zinc-600 mb-6">
              {isLogin ? 'Great to see you again!' : 'Let\'s get you started'}
            </Text>
            {error && (
              <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon aria-hidden="true" className="h-5 w-5 text-red-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{error}</h3>
        </div>
      </div>
    </div>
        )}
            <FieldGroup>
              {!isLogin && (
                <>
                <Field>
                  <Label>First Name</Label>
                  <Input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    className="w-full"
                  />
                </Field>
                <Field>
                  <Label>Last Name</Label>
                  <Input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    className="w-full"
                  />
                </Field>
                </>
              )}
              <Field>
                <Label>Email</Label>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full"
                />
              </Field>
              <Field>
                <Label>Password</Label>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full"
                />
              </Field>
            </FieldGroup>
          </Fieldset>
          <div className="mt-6">
            <Button type="submit" color="sprout" className="w-full">
              {isLogin ? 'Login' : 'Sign Up'}
            </Button>
          </div>
        </form>
        <div className="mt-4 text-center">
          <Button
            plain
            onClick={() => setIsLogin(!isLogin)}
          >
            {isLogin ? 'Need an account? Sign up' : 'Already have an account? Login'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AuthenticationPage
