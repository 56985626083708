import { Button } from '../../components/catalyst/button'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../components/catalyst/dialog'
import { Field, FieldGroup, Label, Description, ErrorMessage } from '../../components/catalyst/fieldset'
import { Input } from '../../components/catalyst/input'
import { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline'
import { Text } from '../../components/catalyst/text'
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../components/catalyst/alert'
import toast, { Toaster } from "react-hot-toast";

const UPDATE_INVOICE = gql`
  mutation ModifyInvoice($input: ModifyInvoiceInput!) {
    modifyInvoice(input: $input) {
      invoice {
        id
        dueDate
        invoiceDate
        lineItems {
          id
          description
          unitPrice
          quantity
        }
      }
    }
  }
`;

const DELETE_INVOICE = gql`
  mutation DeleteInvoice($input: DeleteInvoiceInput!) {
    deleteInvoice(input: $input) {
      success
    }
  }
`;

const emptyLineItem = {
  id: crypto.randomUUID(),
  description: '',
  quantity: 1,
  unitPrice: 0
};

export default function ModifyInvoice({ invoice, refetch, ...props }) {
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toISOString().split('T')[0];
  };

  const [isOpen, setIsOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [dueDate, setDueDate] = useState(formatDateForInput(invoice?.dueDate))
  const [invoiceDate, setInvoiceDate] = useState(formatDateForInput(invoice?.invoiceDate) || formatDateForInput(new Date()))
  const [lineItems, setLineItems] = useState(
    invoice?.lineItems?.map(item => ({
      ...item,
      id: item.id || crypto.randomUUID()
    })) || [{ ...emptyLineItem }]
  );

  const [updateInvoice, { loading }] = useMutation(UPDATE_INVOICE, {
    onCompleted: () => {
      setIsOpen(false)
      refetch()
    }
  })

  const [deleteInvoice] = useMutation(DELETE_INVOICE, {
    onCompleted: () => {
      toast.success("Invoice deleted");
      handleClose();
      refetch();
    },
  });

  const handleDeleteInvoice = async () => {
    setDeleteDialogOpen(false);
    await deleteInvoice({
      variables: { input: { id: invoice.uuid } },
    });
  };

  const handleClose = () => {
    setIsOpen(false)
    setErrors({})
    setShowErrors(false)
  }

  const validateForm = () => {
    const newErrors = {}
    if (!dueDate) newErrors.dueDate = 'Due date is required'
    if (!invoiceDate) newErrors.invoiceDate = 'Invoice date is required'
    
    const lineItemErrors = []
    lineItems.forEach((item, index) => {
      const itemErrors = {}
      if (!item.description) itemErrors.description = 'Description is required'
      if (!item.quantity || item.quantity <= 0) itemErrors.quantity = 'Valid quantity is required'
      if (!item.unitPrice || item.unitPrice <= 0) itemErrors.unitPrice = 'Valid price is required'
      if (Object.keys(itemErrors).length > 0) lineItemErrors[index] = itemErrors
    })
    if (lineItemErrors.length > 0) newErrors.lineItems = lineItemErrors

    return newErrors
  }

  const handleSave = (e) => {
    e.preventDefault()
    
    const newErrors = validateForm()
    setErrors(newErrors)
    setShowErrors(true)

    if (Object.keys(newErrors).length === 0) {
      updateInvoice({
        variables: {
          input: {
            id: invoice.uuid,
            dueDate,
            invoiceDate,
            lineItems: lineItems.map(item => ({
              id: item.id,
              description: item.description,
              quantity: parseFloat(item.quantity),
              unitPrice: parseFloat(item.unitPrice)
            }))
          }
        }
      })
    }
  }

  const addLineItem = () => {
    setLineItems([...lineItems, { ...emptyLineItem, id: crypto.randomUUID() }]);
  };

  const removeLineItem = (id) => {
    setLineItems(lineItems.filter(item => item.id !== id));
  };

  const updateLineItem = (id, field, value) => {
    setLineItems(lineItems.map(item => 
      item.id === id ? { ...item, [field]: value } : item
    ));
  };

  const calculateTotal = () => {
    return lineItems.reduce((sum, item) => 
      sum + (parseFloat(item.quantity || 0) * parseFloat(item.unitPrice || 0)), 
    0)
  }

  return (
    <>
      <Button outline type="button" onClick={() => setIsOpen(true)}>
        {props.children}
      </Button>

      <Alert open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <AlertTitle>Are you sure you want to delete this invoice?</AlertTitle>
        <AlertDescription>
          This action cannot be undone. All line items and payment records associated with this invoice will be permanently deleted.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="red" onClick={handleDeleteInvoice}>Delete Invoice</Button>
        </AlertActions>
      </Alert>

      <Dialog size='2xl' open={isOpen} onClose={handleClose}>
        <DialogTitle>Edit Invoice</DialogTitle>
        <DialogDescription>
          Modify invoice details and line items.
        </DialogDescription>
        <form onSubmit={handleSave}>
          <DialogBody>
            <FieldGroup>
              <div className="grid grid-cols-2 gap-4">
                <Field>
                  <Label>Invoice Date</Label>
                  <Input 
                    type="date" 
                    value={invoiceDate} 
                    onChange={(e) => setInvoiceDate(e.target.value)}
                    invalid={showErrors && !!errors.invoiceDate}
                  />
                  {showErrors && errors.invoiceDate && <ErrorMessage>{errors.invoiceDate}</ErrorMessage>}
                </Field>
                <Field>
                  <Label>Due Date</Label>
                  <Input 
                    type="date" 
                    value={dueDate} 
                    onChange={(e) => setDueDate(e.target.value)}
                    invalid={showErrors && !!errors.dueDate}
                  />
                  {showErrors && errors.dueDate && <ErrorMessage>{errors.dueDate}</ErrorMessage>}
                </Field>
              </div>

              <div className="space-y-4">
                <Field>
                  <Label>Line Items</Label>
                </Field>

                <div className="grid grid-cols-[1fr,80px,100px,40px] gap-4 items-start px-1">
                  <Text className="text-sm font-medium text-gray-500">Item Description</Text>
                  <Text className="text-sm font-medium text-gray-500">Qty</Text>
                  <Text className="text-sm font-medium text-gray-500">Price</Text>
                  <div className="w-8" />
                </div>

                <div className="space-y-3">
                  {lineItems.map((item) => (
                    <div key={item.id || crypto.randomUUID()} className="grid grid-cols-[1fr,80px,100px,40px] gap-4 items-start">
                      <Field>
                        <Input
                          placeholder="Description"
                          value={item.description}
                          onChange={(e) => updateLineItem(item.id, 'description', e.target.value)}
                          invalid={showErrors && errors.lineItems?.[item.id]?.description}
                        />
                        {showErrors && errors.lineItems?.[item.id]?.description && 
                          <ErrorMessage>{errors.lineItems[item.id].description}</ErrorMessage>
                        }
                      </Field>
                      <Field>
                        <Input
                          type="number"
                          placeholder="Qty"
                          value={item.quantity}
                          onChange={(e) => updateLineItem(item.id, 'quantity', e.target.value)}
                          invalid={showErrors && errors.lineItems?.[item.id]?.quantity}
                          className="w-20 text-center"
                        />
                      </Field>
                      <Field>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="Price"
                          value={item.unitPrice}
                          onChange={(e) => updateLineItem(item.id, 'unitPrice', e.target.value)}
                          invalid={showErrors && errors.lineItems?.[item.id]?.unitPrice}
                          className="w-24 text-center"
                        />
                      </Field>
                      <Button
                        type="button"
                        onClick={() => removeLineItem(item.id)}
                        color="red"
                        outline
                        size="sm"
                        className="mt-1"
                      >
                        <TrashIcon className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}

                  <div className="pt-2">
                    <Button
                      type="button"
                      onClick={addLineItem}
                      outline
                      size="sm"
                      className="w-full"
                    >
                      <PlusIcon className="h-4 w-4 mr-1.5" />
                      Add Line Item
                    </Button>
                  </div>
                </div>
              </div>

              <div className="flex justify-end border-t pt-4">
                <div className="text-right">
                  <Field>
                    <Label>Total</Label>
                    <div className="text-xl font-semibold">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                      }).format(calculateTotal())}
                    </div>
                  </Field>
                </div>
              </div>
            </FieldGroup>
          </DialogBody>
          <DialogActions className="flex justify-between">
            <Button 
              color="red" 
              type="button" 
              onClick={() => setDeleteDialogOpen(true)}
            >
              Delete Invoice
            </Button>
            <div className="flex gap-2">
              <Button plain onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" color="sprout" disabled={loading}>
                Save Changes
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
      <Toaster/>
    </>
  )
}
