import { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import Card from "../catalyst/card";
import { Text } from "../catalyst/text";
import { Button } from "../catalyst/button";
import {
  PhoneIcon,
  EnvelopeIcon,
  PlusIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import CreateGuardianModal from "./CreateGuardianModal";
import ModifyGuardianModal from "./ModifyGuardianModal";

const GET_GUARDIANS_BY_STUDENT = gql`
  query GuardiansByStudent($student: ID!) {
    guardiansByStudent(student: $student) {
      avatar
      email
      firstName
      lastName
      role
      id
      guardianProperties {
        activityNotificationsEnabled
        familyRelationship
        finishedSetup
        id
        inviteToken
        lastUpdated
        messageNotificationsEnabled
        phoneNumber
        primaryGuardian
      }
    }
  }
`;

function StudentGuardians({ student }) {
  const [guardians, setGuardians] = useState([]);

  const { data, loading, refetch } = useQuery(GET_GUARDIANS_BY_STUDENT, {
    variables: { student: student.uuid },
  });

  useEffect(() => {
    if (data && !loading) {
      setGuardians(data.guardiansByStudent);
    }
  }, [data, loading]);

  const GuardianCard = ({ guardian }) => (
    <Card className="mb-4">
      <Card.Header>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            <div className="flex-shrink-0">
              {guardian.avatar ? (
                <img
                  className="h-12 w-12 rounded-full"
                  src={guardian.avatar}
                  alt={`${guardian.firstName} ${guardian.lastName}`}
                />
              ) : (
                <div className="h-12 w-12 rounded-full bg-gray-100 flex items-center justify-center">
                  <span className="text-lg text-gray-600">
                    {guardian.firstName[0]}
                    {guardian.lastName[0]}
                  </span>
                </div>
              )}
            </div>
            
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <Card.Title>
                  {guardian.firstName} {guardian.lastName}
                </Card.Title>
                {!guardian.guardianProperties[0].finishedSetup && (
                  <span className="inline-flex items-center rounded-full bg-yellow-50 px-2 py-0.5 text-xs font-medium text-yellow-800">
                    Setup Pending
                  </span>
                )}
              </div>
              <Text className="text-sm text-gray-500">
                {guardian.guardianProperties[0].familyRelationship}
              </Text>
            </div>
          </div>
          
          <div className="flex items-center gap-2">
            {guardian.guardianProperties[0].primaryGuardian ? (
              <span className="inline-flex items-center rounded-full bg-blue-50 px-3 py-1 text-sm font-medium text-blue-700">
                Primary Guardian
              </span>
            ) : (
              <span className="inline-flex items-center rounded-full bg-purple-50 px-3 py-1 text-sm font-medium text-purple-700">
                Secondary Guardian
              </span>
            )}
              <ModifyGuardianModal guardian={guardian} refetch={refetch}>Edit</ModifyGuardianModal>
          </div>
        </div>
      </Card.Header>
      
      <Card.Body>
        <div className="space-y-4">
          {!guardian.guardianProperties[0].finishedSetup && (
            <div className="flex items-center gap-2 p-3 bg-yellow-50 rounded-lg text-yellow-800 text-sm">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
              Guardian hasn't completed account setup
              <Button size="sm" color="yellow">Resend Invite</Button>
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {guardian.guardianProperties[0].phoneNumber && (
              <div className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg">
                <PhoneIcon className="h-5 w-5 text-gray-500" />
                <div className="flex flex-col">
                  <Text className="text-sm text-gray-500">Phone</Text>
                  <Text>{guardian.guardianProperties[0].phoneNumber}</Text>
                </div>
              </div>
            )}

            {guardian.email && (
              <div className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg">
                <EnvelopeIcon className="h-5 w-5 text-gray-500" />
                <div className="flex flex-col">
                  <Text className="text-sm text-gray-500">Email</Text>
                  <Text>{guardian.email}</Text>
                </div>
              </div>
            )}
          </div>

          <div className="mt-4 pt-4 border-t border-gray-200">
            <Text className="text-sm text-gray-500 mb-2">Notification Preferences</Text>
            <div className="flex gap-4">
              <div className="flex items-center gap-2">
                <div className={`w-2 h-2 rounded-full ${guardian.guardianProperties[0].activityNotificationsEnabled ? 'bg-green-500' : 'bg-gray-300'}`} />
                <Text className="text-sm">Activity Updates</Text>
              </div>
              <div className="flex items-center gap-2">
                <div className={`w-2 h-2 rounded-full ${guardian.guardianProperties[0].messageNotificationsEnabled ? 'bg-green-500' : 'bg-gray-300'}`} />
                <Text className="text-sm">Messages</Text>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );

  if (loading) {
    return (
      <Card>
        <Card.Body>
          <div className="text-center py-4">Loading guardians...</div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <Card.Header>
          <div className="flex justify-between items-center">
            <Card.Title>Guardians</Card.Title>
            <CreateGuardianModal studentId={student.uuid} refetch={refetch}><PlusIcon className="h-5 w-5" /> Create Guardian</CreateGuardianModal>
          </div>
        </Card.Header>
        <Card.Body>
          {guardians.length > 0 ? (
            guardians.map((guardian) => (
              <GuardianCard key={guardian.id} guardian={guardian} />
            ))
          ) : (
            <div className="text-center py-12">
              <div className="mx-auto h-12 w-12 text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </div>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No guardians
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Get started by adding a guardian.
              </p>
              <div className="mt-6">
                <CreateGuardianModal studentId={student.uuid} refetch={refetch}><PlusIcon className="h-5 w-5" /> Add Guardian</CreateGuardianModal>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default StudentGuardians;
