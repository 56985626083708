import React, { useState, useEffect, useCallback } from "react";
import { useMutation, gql } from "@apollo/client";
import toast, { Toaster } from "react-hot-toast";
import { UserCircleIcon, DocumentIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import {
  DescriptionList,
  DescriptionTerm,
  DescriptionDetails,
} from "../catalyst/description-list";
import { Alert, AlertActions, AlertDescription, AlertTitle } from "../catalyst/alert";
import { Select } from "../catalyst/select";
import { Input } from "../catalyst/input";
import { Text } from "../catalyst/text";
import { Button } from "../catalyst/button";
import GoogleSearch from '../address-input';
import Card from '../catalyst/card';
import { Switch } from "../catalyst/switch";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

const UPDATE_STUDENT_AND_PROPERTIES = gql`
  mutation ModifyStudentAndProperties($input: ModifyStudentInput!, $propertiesInput: ModifyStudentPropertiesInput!) {
    modifyStudentAndProperties(input: $input, propertiesInput: $propertiesInput) {
      student {
        uuid
      }
    }
  }
`;

const CREATE_STUDENT_HEALTH_RECORD = gql`
  mutation CreateStudentHealthRecord($input: CreateStudentHealthRecordInput!) {
    createStudentHealthRecord(input: $input) {
      healthRecord {
        id
        type
        description
        file
      }
    }
  }
`;

const UPDATE_STUDENT_HEALTH_RECORD = gql`
  mutation UpdateStudentHealthRecord($input: UpdateStudentHealthRecordInput!) {
    updateStudentHealthRecord(input: $input) {
      healthRecord {
        id
      }
    }
  }
`;

const REMOVE_STUDENT_HEALTH_RECORD = gql`
  mutation RemoveStudentHealthRecord($input: RemoveStudentHealthRecordInput!) {
    removeStudentHealthRecord(input: $input) {
      success
    }
  }
`;

function formatDate(utcDateString) {
  const utcDate = new Date(`${utcDateString}Z`);
  const localDate = utcDate.toLocaleString();
  return localDate;
}

function StudentDetails({ student, refetch, editing }) {
  const [studentData, setStudentData] = useState(student);
  const [dirtyStudentData, setDirtyStudentData] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [previewImage, setPreviewImage] = useState(student?.avatar || null);
  const [certificate, setCertificate] = useState(null);
  const [statement, setStatement] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [deleteConditionDialogOpen, setDeleteConditionDialogOpen] = useState(false);
  const [conditionToDelete, setConditionToDelete] = useState(null);
  const [updateStudentAndProperties] = useMutation(UPDATE_STUDENT_AND_PROPERTIES, {
    onCompleted: () => {
      refetch();
      toast.dismiss('savingChanges');
      toast.success("Changes saved");
      setIsSaving(false);
    },
    onError: (error) => {
      const errorMessage = error.graphQLErrors?.[0]?.message;
      toast.dismiss('savingChanges');
      toast.error(errorMessage);
      setIsSaving(false);
    },
  });

  const [newCondition, setNewCondition] = useState({ type: '', description: '', file: null });

  const [createStudentHealthRecord] = useMutation(CREATE_STUDENT_HEALTH_RECORD, {
    onCompleted: () => {
      refetch();
      toast.success("Changes saved");
      setNewCondition({ type: '', description: '', file: null });
    },
    onError: (error) => {
      const errorMessage = error.graphQLErrors?.[0]?.message;
      toast.error(errorMessage || "Failed to add health condition");
    },
  });

  const [removeStudentHealthRecord] = useMutation(REMOVE_STUDENT_HEALTH_RECORD, {
    onCompleted: () => {
      refetch();
      toast.success("Changes saved");
    },
    onError: (error) => {
      const errorMessage = error.graphQLErrors?.[0]?.message;
      toast.error(errorMessage || "Failed to remove health condition");
    },
  });

  useEffect(() => {
    setStudentData(student);
    setDirtyStudentData(false);
  }, [student]);


  const handleInputChange = useCallback((field, value, isProperty = false) => {
    setDirtyStudentData(true);
    setStudentData((prev) => {
      if (isProperty) {
        if (field === 'healthConditions') {
          return {
            ...prev,
            studentProperties: [
              {
                ...prev.studentProperties[0],
                healthConditions: value
              }
            ]
          };
        }
        return {
          ...prev,
          studentProperties: [
            {
              ...prev.studentProperties[0],
              [field]: value
            }
          ]
        };
      }
      return { ...prev, [field]: value };
    });
  }, []);

  const formatPhoneNumber = useCallback((input) => {
    const cleaned = input.replace(/\D/g, '').slice(0, 10);
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    if (cleaned.length > 3) {
      return '(' + cleaned.slice(0, 3) + ') ' + cleaned.slice(3);
    }
    return cleaned;
  }, []);

  const handlePhoneInputChange = useCallback((field, value, isProperty = true) => {
    const formattedValue = formatPhoneNumber(value);
    handleInputChange(field, formattedValue, isProperty);
  }, [formatPhoneNumber, handleInputChange]);

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setDirtyStudentData(true);
      const reader = new FileReader();
      reader.onloadend = () => setPreviewImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleAddressChange = (field, newAddress) => {
    setDirtyStudentData(true);
    setStudentData((prev) => ({
      ...prev,
      studentProperties: [
        {
          ...prev.studentProperties[0],
          [field]: JSON.stringify(newAddress)
        }
      ]
    }));
  };

  const handleCertificateChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setDirtyStudentData(true);
      setCertificate(file);
    } else {
      toast.error("Please upload a PDF file");
    }
  };

  const handleStatementChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setDirtyStudentData(true);
      setStatement(file);
    } else {
      toast.error("Please upload a PDF file");
    }
  };

  const handleConditionFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewCondition(prev => ({ ...prev, file }));
    }
  };

  const handleAddCondition = async (type) => {
    if (!newCondition.description.trim()) return;
    
    try {
      toast.loading('Saving changes...', { id: 'savingChanges' });
      await createStudentHealthRecord({
        variables: {
          input: {
            studentId: studentData.uuid,
            type: type.toLowerCase(),
            description: newCondition.description,
            file: newCondition.file
          }
        }
      });
      toast.dismiss('savingChanges');
    } catch (error) {
      toast.dismiss('savingChanges');
      console.error('Error adding health condition:', error);
    }
  };

  const handleRemoveCondition = async () => {
    try {
      toast.loading('Removing condition...', { id: 'removingCondition' });
      await removeStudentHealthRecord({
        variables: {
          input: {
            id: conditionToDelete
          }
        }
      });
      setDeleteConditionDialogOpen(false);
      setConditionToDelete(null);
      toast.dismiss('removingCondition');
    } catch (error) {
      toast.dismiss('removingCondition');
      toast.error('Failed to remove condition');
      console.error('Error removing health condition:', error);
    }
  };

  useEffect(() => {
    if (dirtyStudentData) {
      const debounce = setTimeout(() => {
        setIsSaving(true);
        toast.loading('Saving changes...', { id: 'savingChanges' });
        updateStudentAndProperties({
          variables: {
            input: {
              id: studentData.uuid,
              firstName: studentData.firstName,
              lastName: studentData.lastName,
              dateOfBirth: studentData.dateOfBirth,
              gender: studentData.gender,
              pickUpPin: parseInt(studentData.pickUpPin),
              avatar: profileImage || null,
            },
            propertiesInput: {
              id: studentData.uuid,
              address: studentData.studentProperties[0].address,
              primaryHospital: studentData.studentProperties[0].primaryHospital,
              primaryHospitalPhone: studentData.studentProperties[0].primaryHospitalPhone,
              primaryHospitalAddress: studentData.studentProperties[0].primaryHospitalAddress,
              primaryPhysician: studentData.studentProperties[0].primaryPhysician,
              primaryPhysicianPhone: studentData.studentProperties[0].primaryPhysicianPhone,
              primaryPhysicianAddress: studentData.studentProperties[0].primaryPhysicianAddress,
              primaryDentist: studentData.studentProperties[0].primaryDentist,
              primaryDentistPhone: studentData.studentProperties[0].primaryDentistPhone,
              primaryDentistAddress: studentData.studentProperties[0].primaryDentistAddress,
              immunizationCertificate: certificate,
              immunizationCertificateValidated: studentData.studentProperties[0].immunizationCertificateValidated,
              statementOfHealth: statement,
              statementOfHealthValidated: studentData.studentProperties[0].statementOfHealthValidated,
            },
          },
        });
      }, 500);
      return () => clearTimeout(debounce);
    }
  }, [studentData, updateStudentAndProperties, profileImage, dirtyStudentData]);

  const handleOpenDeleteConditionDialog = (conditionId) => {
    setDeleteConditionDialogOpen(true);
    setConditionToDelete(conditionId);
  }
  

  return (
    <>
    <Alert open={deleteConditionDialogOpen} onClose={() => setDeleteConditionDialogOpen(false)}>
        <AlertTitle>Are you sure you want to delete this health condition?</AlertTitle>
        <AlertDescription>
          This action cannot be undone.
        </AlertDescription>
        <AlertActions>
          <Button 
            plain 
            onClick={() => {
              setDeleteConditionDialogOpen(false);
              setConditionToDelete(null);
            }}
          >
            Cancel
          </Button>
          <Button color="red" onClick={handleRemoveCondition}>Delete</Button>
        </AlertActions>
      </Alert>
      <Card className="mb-8">
        <Card.Header>
          <Card.Title>Basic Details</Card.Title>
        </Card.Header>
        <Card.Body>
          <DescriptionList>
            <DescriptionTerm>First Name</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <Input
                  type="text"
                  className="md:max-w-96"
                  value={studentData.firstName}
                  onChange={(e) => handleInputChange("firstName", e.target.value)}
                />
              ) : (
                <Text>{studentData.firstName || 'Not Set'}</Text>
              )}
            </DescriptionDetails>

            <DescriptionTerm>Last Name</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <Input
                  type="text"
                  className="md:max-w-96"
                  value={studentData.lastName}
                  onChange={(e) => handleInputChange("lastName", e.target.value)}
                />
              ) : (
                <Text>{studentData.lastName || 'Not Set'}</Text>
              )}
            </DescriptionDetails>

            <DescriptionTerm>Date of Birth</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <Input
                  type="date"
                  className="md:max-w-96"
                  value={studentData.dateOfBirth}
                  onChange={(e) => handleInputChange("dateOfBirth", e.target.value)}
                />
              ) : (
                <Text>{studentData.dateOfBirth || 'Not Set'}</Text>
              )}
            </DescriptionDetails>

            <DescriptionTerm>Gender</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <Select
                  className="md:max-w-96"
                  value={studentData.gender}
                  onChange={(e) => handleInputChange("gender", e.target.value)}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Select>
              ) : (
                <Text>{studentData.gender}</Text>
              )}
            </DescriptionDetails>

            <DescriptionTerm>Pick Up Pin</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <Input
                  type="number"
                  className="md:max-w-96"
                  value={studentData.pickUpPin}
                  onChange={(e) => handleInputChange("pickUpPin", e.target.value)}
                />
              ) : (
                <Text>{studentData.pickUpPin || 'Not Set'}</Text>
              )}
            </DescriptionDetails>
            <DescriptionTerm>Address</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <div className="md:max-w-96">
                  <GoogleSearch
                    name="address"
                    onChange={(newAddress) => handleAddressChange("address", newAddress)}
                    value={studentData.studentProperties[0].address ? JSON.parse(studentData.studentProperties[0].address) : null}
                  />
                </div>
              ) : (
                <Text>{studentData.studentProperties[0].address ? JSON.parse(studentData.studentProperties[0].address)?.label : 'Not Set'}</Text>
              )}
            </DescriptionDetails>
            <DescriptionTerm>Added to Sprout</DescriptionTerm>
            <DescriptionDetails>
              <Text>{formatDate(studentData.createdAt) || 'Not Set'}</Text>
            </DescriptionDetails>
          </DescriptionList>
        </Card.Body>
      </Card>

      <Card className="mb-8">
        <Card.Header>
          <Card.Title>Student Picture</Card.Title>
        </Card.Header>
        <Card.Body>
          <DescriptionList>
            <DescriptionTerm>Profile Image</DescriptionTerm>
            <DescriptionDetails>
              <div className="mt-2 flex items-center space-x-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
                <div className="flex-shrink-0">
                  {previewImage ? (
                    <img
                      src={previewImage}
                      alt="Profile preview"
                      className="w-16 h-16 object-cover rounded-full border-2 border-white shadow-md"
                    />
                  ) : (
                    <div className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center">
                      <UserCircleIcon className="h-8 w-8 text-gray-400" />
                    </div>
                  )}
                </div>
                <div className="flex flex-col space-y-2">
                  <Button
                    as="label"
                    color="sprout"
                    htmlFor="file-upload"
                    className="cursor-pointer"
                  >
                    {previewImage ? 'Change Picture' : 'Upload Picture'}
                  </Button>
                  <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    onChange={handleProfileImageChange}
                    accept="image/*"
                  />
                  <Text className="text-sm text-gray-500">
                    JPG, GIF or PNG
                  </Text>
                </div>
              </div>
            </DescriptionDetails>
          </DescriptionList>
        </Card.Body>
      </Card>

      <Card className="mb-8">
        <Card.Header>
          <Card.Title>Health Information</Card.Title>
        </Card.Header>
        <Card.Body>
          <DescriptionList>
            <DescriptionTerm>Primary Hospital</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <Input
                  type="text"
                  className="md:max-w-96"
                  value={studentData.studentProperties[0].primaryHospital || ''}
                  onChange={(e) => handleInputChange("primaryHospital", e.target.value, true)}
                />
              ) : (
                <Text>{studentData.studentProperties[0].primaryHospital || 'Not Set'}</Text>
              )}
            </DescriptionDetails>

            <DescriptionTerm>Primary Hospital Phone</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <Input
                  className="md:max-w-96"
                  value={studentData.studentProperties[0].primaryHospitalPhone || ''}
                  onChange={(e) => handlePhoneInputChange("primaryHospitalPhone", e.target.value, true)}
                  maxLength={14}
                />
              ) : (
                <Text>{studentData.studentProperties[0].primaryHospitalPhone || 'Not Set'}</Text>
              )}
            </DescriptionDetails>

            <DescriptionTerm>Primary Hospital Address</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <div className="md:max-w-96">
                  <GoogleSearch
                    name="primaryHospitalAddress"
                    onChange={(newAddress) => handleAddressChange("primaryHospitalAddress", newAddress)}
                    value={studentData.studentProperties[0].primaryHospitalAddress ? JSON.parse(studentData.studentProperties[0].primaryHospitalAddress) : null}
                  />
                </div>
              ) : (
                <Text>{studentData.studentProperties[0].primaryHospitalAddress ? JSON.parse(studentData.studentProperties[0].primaryHospitalAddress)?.label : 'Not Set'}</Text>
              )}
            </DescriptionDetails>
            <DescriptionTerm>Primary Physician</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <Input
                  type="text"
                  className="md:max-w-96"
                  value={studentData.studentProperties[0].primaryPhysician || ''}
                  onChange={(e) => handleInputChange("primaryPhysician", e.target.value, true)}
                />
              ) : (
                <Text>{studentData.studentProperties[0].primaryPhysician || 'Not Set'}</Text>
              )}
            </DescriptionDetails>

            <DescriptionTerm>Primary Physician Phone</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <Input
                  className="md:max-w-96"
                  value={studentData.studentProperties[0].primaryPhysicianPhone || ''}
                  onChange={(e) => handlePhoneInputChange("primaryPhysicianPhone", e.target.value, true)}
                  maxLength={14}
                />
              ) : (
                <Text>{studentData.studentProperties[0].primaryPhysicianPhone || 'Not Set'}</Text>
              )}
            </DescriptionDetails>

            <DescriptionTerm>Primary Physician Address</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <div className="md:max-w-96">
                  <GoogleSearch
                    name="primaryPhysicianAddress"
                    onChange={(newAddress) => handleAddressChange("primaryPhysicianAddress", newAddress)}
                    value={studentData.studentProperties[0].primaryPhysicianAddress ? JSON.parse(studentData.studentProperties[0].primaryPhysicianAddress) : null}
                  />
                </div>
              ) : (
                <Text>{studentData.studentProperties[0].primaryPhysicianAddress ? JSON.parse(studentData.studentProperties[0].primaryPhysicianAddress)?.label : 'Not Set'}</Text>
              )}
            </DescriptionDetails>
            <DescriptionTerm>Primary Dentist</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <Input
                  type="text"
                  className="md:max-w-96"
                  value={studentData.studentProperties[0].primaryDentist || ''}
                  onChange={(e) => handleInputChange("primaryDentist", e.target.value, true)}
                />
              ) : (
                <Text>{studentData.studentProperties[0].primaryDentist || 'Not Set'}</Text>
              )}
            </DescriptionDetails>

            <DescriptionTerm>Primary Dentist Phone</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <Input
                  className="md:max-w-96"
                  value={studentData.studentProperties[0].primaryDentistPhone || ''}
                  onChange={(e) => handlePhoneInputChange("primaryDentistPhone", e.target.value, true)}
                  maxLength={14}
                />
              ) : (
                <Text>{studentData.studentProperties[0].primaryDentistPhone || 'Not Set'}</Text>
              )}
            </DescriptionDetails>

            <DescriptionTerm>Primary Dentist Address</DescriptionTerm>
            <DescriptionDetails>
              {editing ? (
                <div className="md:max-w-96">
                  <GoogleSearch
                    name="primaryDentistAddress"
                    onChange={(newAddress) => handleAddressChange("primaryDentistAddress", newAddress)}
                    value={studentData.studentProperties[0].primaryDentistAddress ? JSON.parse(studentData.studentProperties[0].primaryDentistAddress) : null}
                  />
                </div>
              ) : (
                <Text>{studentData.studentProperties[0].primaryDentistAddress ? JSON.parse(studentData.studentProperties[0].primaryDentistAddress)?.label : 'Not Set'}</Text>
              )}
            </DescriptionDetails>
          </DescriptionList>
        </Card.Body>
      </Card>

      <Card className="mb-8">
        <Card.Header>
          <Card.Title>Health Conditions</Card.Title>
        </Card.Header>
        <Card.Body>
          <DescriptionList>
            {['Allergies', 'Medical Conditions', 'Medications'].map((section) => (
              <React.Fragment key={section}>
                <DescriptionTerm>{section}</DescriptionTerm>
                <DescriptionDetails>
                  {editing ? (
                    <div className="space-y-4">
                      <div className="flex flex-col gap-2">
                        <div className="flex gap-2">
                          <Input
                            type="text"
                            className="md:max-w-96"
                            placeholder={`Add new ${section.toLowerCase()}...`}
                            value={newCondition.type === section.toLowerCase() ? newCondition.description : ''}
                            onChange={(e) => setNewCondition(prev => ({ 
                              ...prev,
                              type: section.toLowerCase(), 
                              description: e.target.value 
                            }))}
                          />
                          <Button
                            color="sprout"
                            onClick={() => handleAddCondition(section.toLowerCase())}
                          >
                            Add
                          </Button>
                        </div>
                        <div className="flex items-center gap-2">
                          <Button
                            as="label"
                            htmlFor={`condition-file-${section}`}
                            color="white"
                            size="sm"
                            className="cursor-pointer flex items-center gap-1"
                          >
                            <PaperClipIcon className="h-4 w-4" />
                            {newCondition.type === section.toLowerCase() && newCondition.file
                              ? 'Change File'
                              : 'Attach File'
                            }
                          </Button>
                          <input
                            id={`condition-file-${section}`}
                            type="file"
                            className="hidden"
                            onChange={handleConditionFileChange}
                          />
                          {newCondition.type === section.toLowerCase() && newCondition.file && (
                            <Text className="text-sm text-gray-500">
                              {newCondition.file.name}
                            </Text>
                          )}
                        </div>
                      </div>
                      <div className="space-y-2">
                        {studentData.studentHealthRecords
                          .filter(condition => condition.type === section.toLowerCase())
                          .map((condition) => (
                            <div 
                              key={condition.id} 
                              className="flex items-center gap-4 p-3 bg-gray-50 rounded-lg border border-gray-200 hover:bg-gray-100 transition-colors"
                            >
                              <div className="flex-1 min-w-0">
                                <Text className="font-medium truncate">
                                  {condition.description}
                                </Text>
                              </div>
                              <div className="flex items-center gap-3 flex-shrink-0">
                                {condition.file && (
                                  <a
                                    href={condition.file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1"
                                  >
                                    <PaperClipIcon className="h-4 w-4" />
                                    View
                                  </a>
                                )}
                                <Button
                                  color="red"
                                  variant="ghost"
                                  size="sm"
                                  className="flex items-center gap-1"
                                  onClick={() => handleOpenDeleteConditionDialog(condition.id)}
                                >
                                  <XCircleIcon className="h-4 w-4" />
                                  Remove
                                </Button>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-2">
                      {studentData.studentHealthRecords
                        .filter(condition => condition.type === section.toLowerCase())
                        .map((condition, index) => (
                          <div 
                            key={index} 
                            className="flex items-center gap-4 p-3 bg-gray-50 rounded-lg border border-gray-200"
                          >
                            <div className="flex-1 min-w-0">
                              <Text className="font-medium truncate">
                                {condition.description}
                              </Text>
                            </div>
                            {condition.file && (
                              <a
                                href={condition.file}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1 flex-shrink-0"
                              >
                                <PaperClipIcon className="h-4 w-4" />
                                View Attachment
                              </a>
                            )}
                          </div>
                        ))
                      }
                      {studentData.studentHealthRecords
                        .filter(condition => condition.type === section.toLowerCase())
                        .length === 0 && (
                        <Text className="text-gray-500 italic">No {section.toLowerCase()} listed</Text>
                      )}
                    </div>
                  )}
                </DescriptionDetails>
              </React.Fragment>
            ))}
          </DescriptionList>
        </Card.Body>
      </Card>

      <Card className="mb-8">
        <Card.Header>
          <Card.Title>Health Records</Card.Title>
        </Card.Header>
        <Card.Body>
          <DescriptionList>
            <DescriptionTerm>Certificate of Immunization</DescriptionTerm>
            <DescriptionDetails>
              <div className="mt-2 p-4 bg-white rounded-lg border border-gray-200 shadow-sm">
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    {studentData.studentProperties[0].immunizationCertificate ? (
                      <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                        <DocumentIcon className="h-6 w-6 text-blue-600" />
                      </div>
                    ) : (
                      <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
                        <DocumentIcon className="h-6 w-6 text-gray-400" />
                      </div>
                    )}
                  </div>
                  <div className="flex-grow">
                    <h4 className="text-sm font-medium text-gray-900">
                      {studentData.studentProperties[0].immunizationCertificate ? 'Certificate Uploaded' : 'No Certificate'}
                    </h4>
                    <p className="text-sm text-gray-500">
                      {studentData.studentProperties[0].immunizationCertificate ? 'PDF file' : 'Upload a PDF file'}
                    </p>
                    {studentData.studentProperties[0].immunizationCertificateUploadedAt && (
                      <p className="text-xs text-gray-500 mt-1">
                        Uploaded at {formatDate(studentData.studentProperties[0].immunizationCertificateUploadedAt)}
                      </p>
                    )}
                    {studentData.studentProperties[0].immunizationCertificateValidatedAt && (
                      <p className="text-xs text-gray-500">
                        Validated at {formatDate(studentData.studentProperties[0].immunizationCertificateValidatedAt)}
                      </p>
                    )}
                  </div>
                  <div className="flex-shrink-0">
                    <Button
                      as="label"
                      htmlFor="certificate-upload"
                      color="sprout"
                      className="cursor-pointer"
                    >
                      {studentData.studentProperties[0].immunizationCertificate ? 'Change' : 'Upload'}
                    </Button>
                    <input
                      id="certificate-upload"
                      type="file"
                      className="hidden"
                      onChange={handleCertificateChange}
                      accept="application/pdf"
                    />
                  </div>
                </div>
                {studentData.studentProperties[0].immunizationCertificate && (
                  <div className="mt-3 flex items-center justify-between">
                    <a
                      href={studentData.studentProperties[0].immunizationCertificate}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-blue-600 hover:text-blue-800 font-medium"
                    >
                      View Certificate
                    </a>
                    <div className="flex items-center">
                      <span className="mr-2 text-sm text-gray-600">Validated:</span>
                      <Switch
                        checked={studentData.studentProperties[0].immunizationCertificateValidated}
                        onChange={(checked) => handleInputChange('immunizationCertificateValidated', checked, true)}
                        color="sprout"
                      />
                      {studentData.studentProperties[0].immunizationCertificateValidated ? (
                        <CheckCircleIcon className="h-5 w-5 text-green-500 ml-2" />
                      ) : (
                        <XCircleIcon className="h-5 w-5 text-red-500 ml-2" />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </DescriptionDetails>
            <DescriptionTerm>Statement of Health</DescriptionTerm>
            <DescriptionDetails>
              <div className="mt-2 p-4 bg-white rounded-lg border border-gray-200 shadow-sm">
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    {studentData.studentProperties[0].statementOfHealth ? (
                      <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                        <DocumentIcon className="h-6 w-6 text-blue-600" />
                      </div>
                    ) : (
                      <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
                        <DocumentIcon className="h-6 w-6 text-gray-400" />
                      </div>
                    )}
                  </div>
                  <div className="flex-grow">
                    <h4 className="text-sm font-medium text-gray-900">
                      {studentData.studentProperties[0].statementOfHealth ? 'Statement Uploaded' : 'No Statement'}
                    </h4>
                    <p className="text-sm text-gray-500">
                      {studentData.studentProperties[0].statementOfHealth ? 'PDF file' : 'Upload a PDF file'}
                    </p>
                    {studentData.studentProperties[0].statementOfHealthUploadedAt && (
                      <p className="text-xs text-gray-500 mt-1">
                        Uploaded at {formatDate(studentData.studentProperties[0].statementOfHealthUploadedAt)}
                      </p>
                    )}
                    {studentData.studentProperties[0].statementOfHealthValidatedAt && (
                      <p className="text-xs text-gray-500">
                        Validated at {formatDate(studentData.studentProperties[0].statementOfHealthValidatedAt)}
                      </p>
                    )}
                  </div>
                  <div className="flex-shrink-0">
                    <Button
                      as="label"
                      htmlFor="statement-upload"
                      color="sprout"
                      className="cursor-pointer"
                    >
                      {studentData.studentProperties[0].statementOfHealth ? 'Change' : 'Upload'}
                    </Button>
                    <input
                      id="statement-upload"
                      type="file"
                      className="hidden"
                      onChange={handleStatementChange}
                      accept="application/pdf"
                    />
                  </div>
                </div>
                {studentData.studentProperties[0].statementOfHealth && (
                  <div className="mt-3 flex items-center justify-between">
                    <a
                      href={studentData.studentProperties[0].statementOfHealth}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-blue-600 hover:text-blue-800 font-medium"
                    >
                      View Statement
                    </a>
                    <div className="flex items-center">
                      <span className="mr-2 text-sm text-gray-600">Validated:</span>
                      <Switch
                        checked={studentData.studentProperties[0].statementOfHealthValidated}
                        onChange={(checked) => handleInputChange('statementOfHealthValidated', checked, true)}
                        color="sprout"
                      />
                      {studentData.studentProperties[0].statementOfHealthValidated ? (
                        <CheckCircleIcon className="h-5 w-5 text-green-500 ml-2" />
                      ) : (
                        <XCircleIcon className="h-5 w-5 text-red-500 ml-2" />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </DescriptionDetails>
          </DescriptionList>
        </Card.Body>
      </Card>
      <Toaster/>
    </>
  );
}

export default StudentDetails;
