import { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import Card from "../catalyst/card";
import { Text } from "../catalyst/text";
import { Button } from "../catalyst/button";
import { 
  ClockIcon, 
  UserIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  PlusIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import CreateAttendanceRecord from "../../modals/CreateAttendanceRecord/CreateAttendanceRecord";
import UpdateAttendanceRecord from "../../modals/EditAttendanceRecord/EditAttendanceRecord";
import DeleteAttendanceRecord from "../../modals/DeleteAttendanceRecord/DeleteAttendanceRecord";

const GET_ATTENDANCE_RECORDS_BY_STUDENT = gql`
  query GetAttendanceRecordsByStudent($studentId: ID!) {
    attendanceRecordsByStudent(student_Id: $studentId) {
      edges {
        node {
          uuid
          date
          signedInAt
          signedOutAt
          signedInBy {
            id
            firstName
            lastName
            role
            guardianProperties {
              familyRelationship
            }
          }
          signedOutBy {
            id
            firstName
            lastName
            role
            guardianProperties {
              familyRelationship
            }
          }
        }
      }
    }
  }
`;

function StudentAttendance({ student, refetchStudent }) {
  const [records, setRecords] = useState([]);

  const { data, loading, refetch } = useQuery(GET_ATTENDANCE_RECORDS_BY_STUDENT, {
    variables: { studentId: student.uuid },
  });

  useEffect(() => {
    if (data && !loading) {
      setRecords(data.attendanceRecordsByStudent.edges.map((edge) => edge.node));
    }
  }, [data, loading]);

  useEffect(() => {
    refetchStudent();
  }, [records]);

  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    // Parse the date components directly from YYYY-MM-DD format
    const [year, month, day] = dateString.split('-').map(Number);
    
    // Create a date string that the browser will parse in a timezone-agnostic way
    return new Date(year, month - 1, day).toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const AttendanceCard = ({ record }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const calculateDuration = () => {
      if (!record.signedOutAt) return null;
      const start = new Date(record.signedInAt);
      const end = new Date(record.signedOutAt);
      const diff = Math.abs(end - start);
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      return { hours, minutes };
    };

    const duration = calculateDuration();

    return (
      <Card className="mb-4">
        <Card.Header className={`${!isExpanded && "border-b-0"}`}>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <Button outline onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? (
                  <ChevronUpIcon className="h-5 w-5 text-gray-400" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                )}
              </Button>
              <div>
                <div className="flex items-center gap-2">
                  <Text className="font-medium text-xl">
                    {formatDate(record.date)}
                  </Text>
                  <span className={`inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium ${
                    record.signedOutAt 
                      ? "bg-green-50 text-green-600" 
                      : "bg-yellow-50 text-yellow-600"
                  }`}>
                    {record.signedOutAt ? "Signed Out" : "Signed In"}
                  </span>
                </div>
                {duration && (
                  <Text className="text-sm text-gray-500">
                    Duration: {duration.hours}h {duration.minutes}m
                  </Text>
                )}
              </div>
            </div>
            <div className="flex items-center gap-2">
            <UpdateAttendanceRecord 
              student={student} 
              record={record} 
              refetch={refetch}
            />
            <DeleteAttendanceRecord 
              record={record} 
              refetch={refetch}
            >
              <TrashIcon className="h-5 w-5 text-gray-400" />
              </DeleteAttendanceRecord>
            </div>
          </div>
        </Card.Header>

        {isExpanded && (
          <Card.Body>
            <div className="relative">
              <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-gray-200" />

              {/* Sign In Event */}
              <div className="relative flex gap-4 pb-8">
                <div className="absolute left-4 -translate-x-1/2">
                  <div className="h-8 w-8 rounded-full bg-blue-100 border-2 border-white shadow flex items-center justify-center">
                    <UserIcon className="h-4 w-4 text-blue-600" />
                  </div>
                </div>
                <div className="ml-12">
                  <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                    <Text className="font-medium text-gray-900">Signed In</Text>
                    <Text className="text-sm text-gray-500">
                      at {formatTime(record.signedInAt)}
                    </Text>
                  </div>
                  <div className="mt-2 flex items-center gap-2 bg-gray-50 px-4 py-2 rounded-lg">
                    <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
                      <span className="text-sm font-medium text-gray-600">
                        {record.signedInBy.firstName[0]}
                        {record.signedInBy.lastName[0]}
                      </span>
                    </div>
                    <div>
                      <Text className="font-medium">
                        {record.signedInBy.firstName} {record.signedInBy.lastName}
                      </Text>
                      <Text className="text-sm text-gray-600">
                        {record.signedInBy.guardianProperties[0].familyRelationship}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>

              {/* Sign Out Event */}
              <div className="relative flex gap-4">
                <div className="absolute left-4 -translate-x-1/2">
                  <div className={`h-8 w-8 rounded-full border-2 border-white shadow flex items-center justify-center ${
                    record.signedOutAt ? 'bg-green-100' : 'bg-gray-100'
                  }`}>
                    <UserIcon className={`h-4 w-4 ${
                      record.signedOutAt ? 'text-green-600' : 'text-gray-400'
                    }`} />
                  </div>
                </div>
                <div className="ml-12">
                  {record.signedOutAt ? (
                    <>
                      <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                        <Text className="font-medium text-gray-900">
                          Signed Out
                        </Text>
                        <Text className="text-sm text-gray-500">
                          at {formatTime(record.signedOutAt)}
                        </Text>
                      </div>
                      <div className="mt-2 flex items-center gap-2 bg-gray-50 px-4 py-2 rounded-lg">
                        <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
                          <span className="text-sm font-medium text-gray-600">
                            {record.signedOutBy.firstName[0]}
                            {record.signedOutBy.lastName[0]}
                          </span>
                        </div>
                        <div>
                          <Text className="font-medium">
                            {record.signedOutBy.firstName} {record.signedOutBy.lastName}
                          </Text>
                          <Text className="text-sm text-gray-600">
                            {record.signedOutBy.guardianProperties[0].familyRelationship}
                          </Text>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flex items-center h-16">
                      <Text className="text-gray-500 italic">
                        Not signed out yet
                      </Text>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {duration && (
              <div className="mt-6 pt-6 border-t grid grid-cols-3 gap-4">
                <div className="text-center">
                  <Text className="text-sm text-gray-500">Sign In</Text>
                  <Text className="font-medium text-gray-900">
                    {formatTime(record.signedInAt)}
                  </Text>
                </div>
                <div className="text-center border-x">
                  <Text className="text-sm text-gray-500">Duration</Text>
                  <Text className="font-medium text-gray-900">
                    {duration.hours}h {duration.minutes}m
                  </Text>
                </div>
                <div className="text-center">
                  <Text className="text-sm text-gray-500">Sign Out</Text>
                  <Text className="font-medium text-gray-900">
                    {formatTime(record.signedOutAt)}
                  </Text>
                </div>
              </div>
            )}
          </Card.Body>
        )}
      </Card>
    );
  };

  if (loading) {
    return (
      <Card>
        <Card.Body>
          <div className="text-center py-4">Loading attendance records...</div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <Card.Header className="flex justify-between items-center">
          <Card.Title>Attendance History</Card.Title>
          <CreateAttendanceRecord student={student} refetch={refetch}>
            <PlusIcon className="h-5 w-5 text-gray-400" /> Create Record
          </CreateAttendanceRecord>
        </Card.Header>
        <Card.Body>
          {records.length > 0 ? (
            records
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .map((record) => (
                <AttendanceCard key={record.id} record={record} />
              ))
          ) : (
            <div className="text-center py-12">
              <div className="mx-auto h-12 w-12 text-gray-400">
                <ClockIcon className="h-12 w-12" />
              </div>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No attendance records
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                No attendance history available for this student.
              </p>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default StudentAttendance;


