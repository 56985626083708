import { clsx } from 'clsx'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

export default function GoogleSearch({onChange, value, className, disabled}) {

    const selectStyle = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: '8px',
            borderColor: state.isOpen ? '##3b82f6' : '#d1d5db',
            borderWidth: state.isOpen ? '2px' : '1px',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
            ':hover': {
                borderColor: '#3b82f6',
            },
            ':focus': {
                borderColor: '#3b82f6',
            }
        })
    };

    return (
        <span id='test'
            data-slot="control"
            className={clsx([
                // Basic layout
                'relative block w-full',
                // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
                'before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-white before:shadow',
                // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
                'dark:before:hidden',
                // Focus ring
                'after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent sm:after:focus-within:ring-2 sm:after:focus-within:ring-blue-500',
                // Disabled state
                'has-[[data-disabled]]:opacity-50 before:has-[[data-disabled]]:bg-zinc-950/5 before:has-[[data-disabled]]:shadow-none',
                // Invalid state
                'before:has-[[data-invalid]]:shadow-red-500/10',
            ])}
        >
            <GooglePlacesAutocomplete
                apiKey="AIzaSyDvna8dCqvZyuf6Sbre9G5-esB8SuJlqBk"
                selectProps={{
                    classNamePrefix: "searchable-select",
                    styles: selectStyle,
                    placeholder: 'Search for an address...',
                    onChange: onChange,
                    value: value,
                    isDisabled: disabled,
                    defaultInputValue: value?.label,
                    defaultValue: value?.label
                }}

            /> </span>
    )

}