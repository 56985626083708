import { forwardRef } from 'react'
import { useState, useRef, useEffect } from 'react'
import logoIcon from '../../assets/logo-icon.svg'
import { useAuth } from '../../hooks/useAuth'
import { useUser } from '../../hooks/useUser'
import {
  Cog6ToothIcon,
  LightBulbIcon,
  ArrowRightStartOnRectangleIcon,
  AcademicCapIcon,
  ChatBubbleOvalLeftIcon,
  CurrencyDollarIcon,

  HomeIcon,
} from '@heroicons/react/20/solid'
import { clsx } from 'clsx'

import { Avatar } from '../../components/catalyst/avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
  DropdownDivider,
} from '../../components/catalyst/dropdown'
import {
  Navbar,
  NavbarSpacer,
  NavbarSection,
  NavbarItem,
} from '../../components/catalyst/navbar'
import {
  Sidebar,
  SidebarHeader,
  SidebarBody,
  SidebarSection,
  SidebarItem,
  SidebarLabel,
  SidebarSpacer,
  SidebarFooter,
} from '../../components/catalyst/sidebar'
import { Tooltip } from '../../components/catalyst/tooltip'
import { SidebarLayout } from '../../components/catalyst/sidebar-layout'
import { generateAvatar } from '../../utils/generateAvatar'
import { useLocation } from 'wouter'


const SidebarDropdownButton = forwardRef(function SidebarDropdownButton(
  { className, ...props },
  ref
) {
  let containerClasses = clsx(
    // Base
    'flex items-center justify-center rounded-lg text-base/6 font-medium text-zinc-950 sm:text-sm/5',
    // Hover
    'hover:bg-zinc-950/5',
    // Dark mode
    'dark:text-white dark:hover:bg-white/5',
    // Animation
    'transition-transform duration-200 ease-in-out hover:scale-105',
    // Ensure square aspect ratio
    'aspect-square w-8 h-8' // Adjust width and height to ensure square shape
  )

  let iconClasses = clsx(
    // Icon
    '[&>*]:fill-zinc-500 hover:[&>*]:fill-zinc-950',
    // Dark mode icon color
    'dark:[&>*]:fill-zinc-400 dark:hover:[&>*]:fill-white'
  )

  return (
    <DropdownButton
      as="div"
      className={clsx(containerClasses, className)}
      ref={ref}
      {...props}
    >
      <span className={iconClasses}>
        {props.children}
      </span>
    </DropdownButton>
  )
})

const SidebarItemWithTooltip = ({ href, current, icon: Icon, tooltip }) => {
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
  const itemRef = useRef(null)

  useEffect(() => {
    const updatePosition = () => {
      if (itemRef.current) {
        const rect = itemRef.current.getBoundingClientRect()
        setTooltipPosition({
          top: rect.top + rect.height / 2,
          left: rect.right + 2, // 2px offset from the sidebar item
        })
      }
    }

    updatePosition()
    window.addEventListener('resize', updatePosition)
    return () => window.removeEventListener('resize', updatePosition)
  }, [])

  return (
    <Tooltip content={tooltip} style={tooltipPosition}>
      <div ref={itemRef}>
        <SidebarItem href={href} current={current}>
          <Icon />
        </SidebarItem>
      </div>
    </Tooltip>
  )
}

const AuthenticatedUserLayout = ({ children }) => {
  const { logout } = useAuth()
  const { user } = useUser()
  const [location] = useLocation()

  return (
    <>
      <SidebarLayout
        navbar={
          <Navbar>
            <NavbarSection>
              <NavbarItem>
                <img
                  src={logoIcon}
                  alt="Company Logo"
                  className="h-8 w-auto px-1"
                />
              </NavbarItem>
            </NavbarSection>
            <NavbarSpacer />
            <NavbarSection>
              <Dropdown>
                <DropdownButton as={NavbarItem}>
                  <Avatar src={user ?  user?.avatar ? user.avatar : generateAvatar(user.firstName, user.lastName) : null} square />
                </DropdownButton>
                <DropdownMenu className="min-w-64" anchor="bottom end">
                  <DropdownItem onClick={() => logout()}>
                    <ArrowRightStartOnRectangleIcon />
                    <DropdownLabel>Sign out</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavbarSection>
          </Navbar>
        }
        sidebar={
          <Sidebar>
            <SidebarHeader className="flex items-center justify-center">
              <img
                src={logoIcon}
                alt="Company Logo"
                className="h-8 w-auto"
              />
            </SidebarHeader>
            <SidebarBody>
              <SidebarSection>
                <SidebarItemWithTooltip
                  href="/"
                  current={location === '/'}
                  icon={HomeIcon}
                  tooltip="Home"
                />
                <SidebarItemWithTooltip
                  href="/students"
                  current={location.split('/')[1] === 'students'}
                  icon={AcademicCapIcon}
                  tooltip="Students"
                />
                <SidebarItemWithTooltip
                  href="/messaging"
                  current={location.split('/')[1] === 'messaging'}
                  icon={ChatBubbleOvalLeftIcon}
                  tooltip="Messaging"
                />
                <SidebarItemWithTooltip
                  href="/billing"
                  current={location.split('/')[1] === 'billing'}
                  icon={CurrencyDollarIcon}
                  tooltip="Billing"
                />
                <SidebarItemWithTooltip
                  href="/settings"
                  current={location.split('/')[1] === 'settings'}
                  icon={Cog6ToothIcon}
                  tooltip="Settings"
                />
              </SidebarSection>
            </SidebarBody>
            <SidebarFooter className="max-lg:hidden">
              <Dropdown>
                <SidebarDropdownButton>
                  <Avatar
                    src={user ?  user?.avatar ? user.avatar : generateAvatar(user.firstName, user.lastName) : null}
                    className="size-6"
                    square
                    alt="Student Avatar"
                  />
                </SidebarDropdownButton>
                <DropdownMenu className="min-w-64" anchor="bottom end">
                  <DropdownItem onClick={null}>
                    <Cog6ToothIcon />
                    <DropdownLabel>Manage account</DropdownLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem href="/share-feedback">
                    <LightBulbIcon />
                    <DropdownLabel>Share feedback</DropdownLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem onClick={() => logout()}>
                    <ArrowRightStartOnRectangleIcon />
                    <DropdownLabel>Sign out</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </SidebarFooter>
          </Sidebar>
        }
      >
        {children}
      </SidebarLayout>
    </>
  )
}

export default AuthenticatedUserLayout
