import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../components/catalyst/alert'
import { Button } from '../../components/catalyst/button'
import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
const VOID_INVOICE = gql`
  mutation VoidInvoice($input: VoidInvoiceInput!) {
    voidInvoice(input: $input) {
      success
    }
  }
`

export default function VoidInvoice({ invoice, refetch, ...props }) {
  let [isOpen, setIsOpen] = useState(false)
  const [voidInvoice] = useMutation(VOID_INVOICE)

  const handleVoidInvoice = async () => {
    const { data } = await voidInvoice({ 
      variables: { 
        input: { 
          id: invoice.uuid 
        } 
      } 
    })
    
    if (data?.voidInvoice?.success) {
      setIsOpen(false)
      refetch()
    }
  }

  return (
    <>
      <Button outline type="button" onClick={() => setIsOpen(true)}>
        {props.children}
      </Button>
      <Alert open={isOpen} onClose={setIsOpen}>
        <AlertTitle>Are you sure you want to void invoice {invoice.invoiceNumber}?</AlertTitle>
        <AlertDescription>
          This action cannot be undone.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button color="red" onClick={handleVoidInvoice}>Void Invoices</Button>
        </AlertActions>
      </Alert>
    </>
  )
}