import { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GetCSRFToken } from "../utils/getCSRF";
import { API_BASE_URL } from "../config";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authLoaded, setAuthLoaded] = useState(false);


  useEffect(() => {
    const initialize = async () => {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      if (accessToken && refreshToken) {
        checkAuth();
      }
      else {
        setIsAuthenticated(false)
        setAuthLoaded(true)
      }
    };
    initialize();
    const intervalId = setInterval(checkAuth, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);


  const checkAuth = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');

      let response = await fetch(`${API_BASE_URL}/api/v1/check-session`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`

        },
      });

      if (!response.ok && refreshToken) {
        const refreshResponse = await fetch(`${API_BASE_URL}/api/v1/token/refresh`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ refresh: refreshToken }),
        });

        const refreshData = await refreshResponse.json();

        if (refreshResponse.ok) {
          localStorage.setItem('accessToken', refreshData.access);
          localStorage.setItem('refreshToken', refreshData.refresh);

          response = await fetch(`${API_BASE_URL}/api/v1/check-session`, {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${refreshData.access}`
            },
          });
        }
      }
      if (response.ok) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
      setIsAuthenticated(false);
    }
    setAuthLoaded(true);
  };

  const login = async (credentials) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/login`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.errors ? data.errors[0] : "Failed to log in");
      }

      localStorage.setItem('accessToken', data.access);
      localStorage.setItem('refreshToken', data.refresh);

      setIsAuthenticated(true); 
      return null;
    } catch (error) {
      console.error("Error logging in:", error);
      return {
        message: error.message || "An unexpected error occurred during login",
        status: error.response?.status || 500,
      };
    }
  };

  const signup = async (credentials) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/signup`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();
      if (!response.ok) {
        return {
          message: "An unexpected error occurred during signup",
        };
      }

      localStorage.setItem('accessToken', data.access);
      localStorage.setItem('refreshToken', data.refresh);

      setIsAuthenticated(true);
      return null;
    } catch (error) {
      console.error("Error signing up:", error);
      return {
        message: error.message || "An unexpected error occurred during signup",
        status: error.response?.status || 500,
      };
    }
  };

  const logout = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      const response = await fetch(`${API_BASE_URL}/api/v1/logout`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      });

      if (!response.ok) throw new Error("Failed to log out");

      if (response.ok) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }

      setIsAuthenticated(false);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, authLoaded, signup }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProvider };
export default AuthContext;
