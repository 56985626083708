import { useEffect } from "react"
import { Select } from "./catalyst/select";
import PropTypes from 'prop-types';

const tabs = [
  { name: 'Feed', value: "feed" },
  { name: 'Details', value: "details" },
  { name: 'Attendance', value: "attendance" },
  { name: 'Guardians', value: "guardians" },
  { name: 'Enrollment', value: "enrollment" },
  { name: 'Billing', value: "billing" },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function StudentTabs({ selectedTab, setSelectedTab }) {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <Select
          id="tabs"
          name="tabs"
          value={selectedTab}
          onChange={(event) => setSelectedTab(event.target.value)}
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          {tabs.map((tab) => (
            <option value={tab.value} key={tab.value}>{tab.name}</option>
          ))}
        </Select>
      </div>
      <div className="hidden sm:block">
        <nav aria-label="Tabs" className="flex space-x-4">
          {tabs.map((tab) => (
            <a onClick={() => setSelectedTab(tab.value)}
              key={tab.value}
              aria-current={tab.current ? 'page' : undefined}
              className={classNames(
                tab.value === selectedTab ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium cursor-pointer',
              )}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  )
}

StudentTabs.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};
