import { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { useLocation, useRoute } from "wouter";
import { Link } from "../../components/catalyst/link";
import { ChevronLeftIcon } from '@heroicons/react/16/solid'
import { Avatar } from "../../components/catalyst/avatar";
import { generateAvatar } from "../../utils/generateAvatar";
import { Heading } from "../../components/catalyst/heading";
import { Badge } from "../../components/catalyst/badge";
import { Button } from "../../components/catalyst/button";
import StudentTabs from "../../components/student-tabs";
import Spinner from "../../components/catalyst/spinner";
import StudentDetails from "../../components/student/StudentDetails";
import StudentEnrollment from "../../components/student/StudentEnrollment";
import StudentGuardians from "../../components/student/StudentGuardians";
import StudentBilling from "../../components/student/StudentBilling";
import StudentAttendance from "../../components/student/StudentAttendance";
import StudentActivities from "../../components/student/StudentActivities";

const STUDENT_BY_ID = gql`
  query StudentById($id: ID!) {
    studentById(id: $id) {
      id
      uuid
      firstName
      lastName
      gender
      dateOfBirth
      enrolled
      pickUpPin
      archived
      attendanceStatus
      avatar
      createdAt
      family {
        id
        autoPayEnabled
        name
        stripePaymentMethodName
      }
      enrollments {
        active
        archived
        enrollmentDate
        notes
        rate
        rateFrequency
      }
      studentProperties {
        address
        archived
        createdAt
        immunizationCertificate
        primaryDentist
        primaryDentistAddress
        primaryDentistPhone
        primaryHospital
        primaryHospitalAddress
        primaryHospitalPhone
        primaryPhysician
        primaryPhysicianAddress
        primaryPhysicianPhone
        statementOfHealth
        immunizationCertificateUploadedAt
        statementOfHealthUploadedAt
        immunizationCertificateValidated
        statementOfHealthValidated
        immunizationCertificateValidatedAt
        statementOfHealthValidatedAt
      }
      studentHealthRecords {
        createdAt
        description
        file
        id
        type
      }
    }
  }
`;

export default function StudentPage() {
  const [location, setLocation] = useLocation();
  const [match, params] = useRoute("/students/:id/:tab?");
  const [student, setStudent] = useState(null);
  const [selectedTab, setSelectedTab] = useState(params.tab || "feed");
  const [editingDetails, setEditingDetails] = useState(false)
  const [editingEnrollment, setEditingEnrollment] = useState(false)
  const { data, loading, refetch } = useQuery(STUDENT_BY_ID, {
    variables: { id: params.id },
  });

  useEffect(() => {
    if (data) {
      setStudent(data.studentById);
    }
  }, [data]);

  useEffect(() => {
    if (student) {
      console.log(student);
    }
  }, [student]);

  useEffect(() => {
    if (!params.tab && !selectedTab) {
      setSelectedTab('feed');
      return;
    }

    if (params.tab && !selectedTab) {
      setSelectedTab(params.tab);
      return;
    }

    if (params.tab !== selectedTab && params.tab && selectedTab) {
      setLocation(location.replace(/\/[^\/]+$/, '') + '/' + selectedTab);
      return;
    }

    if (!params.tab && selectedTab) {
      setLocation(location + '/' + selectedTab);
    }
    setEditingDetails(false);
  }, [params.tab, selectedTab]);

  useEffect(() => {
    if (data?.studentById) {
      setStudent(data.studentById);
    }
  }, [data]);

  if (loading) {
    return <Spinner />;
  } else if (student) {

    return (
      <>
        <div className="max-lg:hidden">
          <Link href="/students"
            className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
            <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
            Students
          </Link>
        </div>
        <div className="mt-4 flex flex-wrap items-end justify-between gap-4">
          <div className="flex flex-wrap items-center gap-2 justify-center">
            <div>
              <Avatar
                src={student ? student?.avatar ? student.avatar : generateAvatar(student.firstName, student.lastName) : null}
                className="size-10"
                square
                alt=""
              />
            </div>
            <div className="flex items-center">
              <div className="flex flex-wrap items-center gap-x-2 gap-y-2">
                <Heading>{student.firstName} {student.lastName}</Heading>
                <Badge
                  color={
                    student.archived ? "zinc" :
                      student.attendanceStatus === "Signed In" ? "green" :
                        student.attendanceStatus === "Signed Out" ? "red" :
                          "yellow"
                  }
                >
                  {student.archived ? "Archived" : student.attendanceStatus || "Unknown"}
                </Badge>
              </div>
              <div className="mt-2 text-sm/6 text-zinc-500">
                {student.age}
              </div>
            </div>
          </div>

        </div>
        <div className="flex justify-between mt-6 py-4 border-y border-zinc-950/5">
          <StudentTabs selectedTab={params.tab} setSelectedTab={setSelectedTab} />
          {selectedTab === 'details' && !student.archived && (
            <Button outline onClick={() => setEditingDetails(!editingDetails)}>{editingDetails ? 'Done' : 'Edit'}</Button>
          )}
        </div>
        <div className="mt-6">
          {selectedTab === 'feed' && student && (
            <StudentActivities student={student} />
          )}
          {selectedTab === 'details' && student && (
            <StudentDetails student={student} editing={editingDetails} refetch={refetch} />
          )}
          {selectedTab === 'enrollment' && student && (
            <StudentEnrollment student={student} refetch={refetch} editing={editingEnrollment} />
          )}
          {selectedTab === 'guardians' && student && (
            <StudentGuardians student={student} refetch={refetch} />
          )}
          {selectedTab === 'billing' && student && (
            <StudentBilling student={student} refetch={refetch} />
          )}
          {selectedTab === 'attendance' && student && (
            <StudentAttendance student={student} refetchStudent={refetch} />
          )}
        </div>
      </>
    );
  }
}
