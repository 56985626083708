import React, { useState } from 'react'

export const Tooltip = ({ children, content, style }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div
          className="pointer-events-none fixed z-50 ml-2 whitespace-nowrap rounded-md bg-black/70 px-2 py-1 text-sm text-white"
          style={{
            top: `${style.top}px`,
            left: `${style.left}px`,
            transform: 'translateY(-50%)',
          }}
        >
          {content}
        </div>
      )}
    </div>
  )
}
