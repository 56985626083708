import { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Toaster } from "react-hot-toast";
import {
  DescriptionList,
  DescriptionTerm,
  DescriptionDetails,
} from "../catalyst/description-list";
import { Text } from "../catalyst/text";
import Card from "../catalyst/card";
import CreateEnrollment from "./CreateEnrollment";
import ModifyEnrollment from "./ModifyEnrollment";

const GET_PROGRAMS = gql`
  query Programs {
    programs {
      description
      id
      maxEnrollments
      name
      defaultRate
      defaultRateFrequency
    }
  }
`;

const GET_ENROLLMENT_BY_STUDENT = gql`
  query EnrollmentByStudent($student: ID!) {
    enrollmentByStudent(student: $student) {
      enrollmentDate
      id
      notes
      rate
      rateFrequency
      inactiveDate
      active
      program {
        id
        name
      }
    }
  }
`;

const UPDATE_ENROLLMENT = gql`
  mutation ModifyEnrollment($input: ModifyEnrollmentInput!) {
    modifyEnrollment(input: $input) {
      enrollment {
        id
      }
    }
  }
`;

const formatDate = (dateString) => {
  if (!dateString) return '';
  
  // Parse the date components directly from YYYY-MM-DD format
  const [year, month, day] = dateString.split('-').map(Number);
  
  // Create a date string that the browser will parse in a timezone-agnostic way
  return new Date(year, month - 1, day).toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};

const formatDateOnly = (dateString) => {
  if (!dateString) return '';
  
  // Parse the date components directly from YYYY-MM-DD format
  const [year, month, day] = dateString.split('-').map(Number);
  
  // Create a date string that the browser will parse in a timezone-agnostic way
  return new Date(year, month - 1, day).toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};

function StudentEnrollment({ student, refetch, editing }) {
  const [enrollments, setEnrollments] = useState([]);
  const [programs, setPrograms] = useState([]);
  
  const { data: programsData, loading: programsLoading, refetch: refetchPrograms } = useQuery(GET_PROGRAMS);
  const { data: enrollmentData, loading: enrollmentLoading, refetch: refetchEnrollment } = useQuery(GET_ENROLLMENT_BY_STUDENT, {
    variables: { student: student.uuid },
  });

  useEffect(() => {
    if (programsData && !programsLoading) {
      setPrograms(programsData.programs);
    }
  }, [programsData, programsLoading]);

  useEffect(() => {
    if (enrollmentData && !enrollmentLoading) {
      setEnrollments(enrollmentData.enrollmentByStudent);
    }
  }, [enrollmentData, enrollmentLoading]);

  const activeEnrollments = enrollments.filter(e => e.active);
  const inactiveEnrollments = enrollments.filter(e => !e.active);

  const EnrollmentCard = ({ enrollment }) => (
    <Card className="mb-4">
      <Card.Header>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            <Card.Title>{enrollment.program.name}</Card.Title>
            <span className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${
              enrollment.active 
                ? 'bg-green-50 text-green-700' 
                : 'bg-gray-100 text-gray-600'
            }`}>
              {enrollment.active ? 'Active' : 'Inactive'}
            </span>
          </div>
          {enrollment.active && (
            <ModifyEnrollment programs={programs} student={student} refetch={refetchEnrollment} enrollment={enrollment} />
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <DescriptionList>
          <DescriptionTerm>Enrollment Date</DescriptionTerm>
          <DescriptionDetails>
            <Text>{formatDate(enrollment.enrollmentDate)}</Text>
          </DescriptionDetails>

          <DescriptionTerm>Rate</DescriptionTerm>
          <DescriptionDetails>
            <Text>${enrollment.rate}</Text>
          </DescriptionDetails>
          <DescriptionTerm>Billing Frequency</DescriptionTerm>
          <DescriptionDetails>
            <Text>{enrollment.rateFrequency}</Text>
          </DescriptionDetails>
          {enrollment.inactiveDate && (
            <>
              <DescriptionTerm>Inactive Date</DescriptionTerm>
              <DescriptionDetails>
            <Text>{formatDateOnly(enrollment.inactiveDate)}</Text>
              </DescriptionDetails>
            </>
          )}

          {enrollment.notes && (
            <>
              <DescriptionTerm>Notes</DescriptionTerm>
              <DescriptionDetails>
                <Text>{enrollment.notes}</Text>
              </DescriptionDetails>
            </>
          )}
        </DescriptionList>
      </Card.Body>
    </Card>
  );

  return (
    <div className="space-y-6">
      <Card>
        <Card.Header>
          <Card.Title>Active Enrollment</Card.Title>
        </Card.Header>
        <Card.Body>
          {activeEnrollments.length > 0 ? (
            activeEnrollments.map(enrollment => (
              <EnrollmentCard key={enrollment.id} enrollment={enrollment} />
            ))
          ) : (
            <div className="text-center py-12">
              <div className="mx-auto h-12 w-12 text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
              <h3 className="mt-2 text-sm font-medium text-gray-900">No active enrollments</h3>
              <p className="mt-1 text-sm text-gray-500">Get started by creating a new enrollment.</p>
              <div className="mt-6">
                <CreateEnrollment student={student} refetch={refetchEnrollment} refetchPrograms={refetchPrograms} programs={programs} />
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

      {inactiveEnrollments.length > 0 && (
        <Card>
          <Card.Header>
            <Card.Title>Past Enrollments</Card.Title>
          </Card.Header>
          <Card.Body>
            {inactiveEnrollments
              .sort((a, b) => new Date(b.inactiveDate) - new Date(a.inactiveDate))
              .map(enrollment => (
                <EnrollmentCard key={enrollment.id} enrollment={enrollment} />
              ))}
          </Card.Body>
        </Card>
      )}
      
      <Toaster/>
    </div>
  );
}

export default StudentEnrollment;
