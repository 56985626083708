import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./catalyst/table";

export default function SkeletonTable({ columns, rows }) {
    return (
        <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
            <TableHead>
                <TableRow>
                    {Array.from({ length: columns }).map((_, colIndex) => (
                        <TableHeader key={`header-${colIndex}`}>
                            <div className="w-full h-6 bg-gray-100 animate-pulse rounded-lg"></div>
                        </TableHeader>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {Array.from({ length: rows }).map((_, rowIndex) => (
                    <TableRow key={`row-${rowIndex}`}>
                        {Array.from({ length: columns }).map((_, colIndex) => (
                            <TableCell key={`cell-${rowIndex}-${colIndex}`}>
                                <div className="w-full h-6 bg-gray-100 animate-pulse rounded-lg"></div>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}