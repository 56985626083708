import { Button } from "../catalyst/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../catalyst/dialog";
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Description,
} from "../catalyst/fieldset";
import Spinner from "../catalyst/spinner";
import { Input } from "../catalyst/input";
import { Select } from "../catalyst/select";
import { useEffect, useState } from "react";
import GoogleSearch from "../address-input";
import { useLocation } from "wouter";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import toast, { Toaster } from "react-hot-toast";
import { Switch, SwitchField } from "../catalyst/switch";
import { Alert, AlertActions, AlertDescription, AlertTitle } from "../catalyst/alert";

const MODIFY_GUARDIAN = gql`
  mutation ModifyGuardian($input: ModifyGuardianInput!) {
    modifyGuardian(input: $input) {
      guardian {
        id
      }
    }
  }
`;

const DELETE_GUARDIAN = gql`
  mutation DeleteGuardian($input: RemoveGuardianInput!) {
    removeGuardian(input: $input) {
      success
    }
  }
`;

export default function ModifyGuardianModal({ guardian, refetch, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [primaryGuardian, setPrimaryGuardian] = useState(guardian.guardianProperties[0].primaryGuardian);
  const [inviteViaEmail, setInviteViaEmail] = useState(false);
  const [form, setForm] = useState({
    first_name: guardian.firstName,
    last_name: guardian.lastName,
    email: guardian.email,
    phone_number: guardian.guardianProperties[0].phoneNumber,
    relationship: guardian.guardianProperties[0].familyRelationship,
  });
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setForm((prevForm) => ({
      ...prevForm,
      phone_number: formattedPhoneNumber,
    }));
  };

  const handleClose = () => {
    setForm({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      relationship: "",
    });
    setIsOpen(false);
  };

  const [modifyGuardian] = useMutation(MODIFY_GUARDIAN, {
    onCompleted: (data) => {
      toast.dismiss("savingGuardian");
      toast.success("Changes saved");
      console.log("Modify Guare")
      handleClose();
      refetch();
    },
    onError: (error) => {
      toast.dismiss("savingGuardian");
      toast.error("Error saving changes: " + error.message);
    },
  });

  const validateForm = () => {
    const newErrors = {};
    if (!form.first_name) newErrors.first_name = "First name is required";
    if (!form.last_name) newErrors.last_name = "Last name is required";
    if (!form.email) newErrors.email = "Email is required";
    if (!form.phone_number) newErrors.phone_number = "Phone number is required";
    if (!form.relationship || form.relationship === "")
      newErrors.relationship = "Relationship is required";
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newErrors = validateForm();
    setErrors(newErrors);
    setShowErrors(true);

    if (Object.keys(newErrors).length === 0) {
      setSubmitting(true);
      toast.loading("Saving changes...", { id: "savingGuardian" });

      try {
        await modifyGuardian({
          variables: {
            input: {
              id: guardian.id,
              firstName: form.first_name,
              lastName: form.last_name,
              email: form.email,
              phoneNumber: form.phone_number,
              relationship: form.relationship,
              primaryGuardian: primaryGuardian,
              resendInvite: inviteViaEmail,
            },
          },
        });
      } catch (error) {
        console.error("Error creating guardian:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const [deleteGuardian] = useMutation(DELETE_GUARDIAN, {
    onCompleted: () => {
      toast.success("Guardian deleted");
      handleClose();
      refetch();
    },
  });

  const handleDeleteGuardian = async () => {
    setDeleteDialogOpen(false);
    await deleteGuardian({
      variables: { input: { id: guardian.id } },
    });
  };

  return (
    <>
      <Button
        outline
        className="min-w-fit"
        type="button"
        onClick={() => setIsOpen(true)}
        {...props}
      />
      <Alert open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <AlertTitle>Are you sure you want to delete this guardian?</AlertTitle>
        <AlertDescription>
          This action cannot be undone.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="red" onClick={handleDeleteGuardian}>Delete Guardian</Button>
        </AlertActions>
      </Alert>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Modify guardian</DialogTitle>
          <DialogDescription>Modify this guardian.</DialogDescription>
          <DialogBody>
            <Fieldset>
              <FieldGroup>
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
                  <Field>
                    <Label>First name</Label>
                    <Input
                      name="first_name"
                      placeholder="Jon"
                      autoFocus
                      value={form.first_name}
                      onChange={handleChange}
                      invalid={showErrors && !!errors.first_name}
                    />
                    {showErrors && errors.first_name && (
                      <ErrorMessage>{errors.first_name}</ErrorMessage>
                    )}
                  </Field>
                  <Field>
                    <Label>Last name</Label>
                    <Input
                      name="last_name"
                      placeholder="Doe"
                      value={form.last_name}
                      onChange={handleChange}
                      invalid={showErrors && !!errors.last_name}
                    />
                    {showErrors && errors.last_name && (
                      <ErrorMessage>{errors.last_name}</ErrorMessage>
                    )}
                  </Field>
                  <Field>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      name="email"
                      placeholder="jon.doe@example.com"
                      value={form.email}
                      onChange={handleChange}
                      invalid={showErrors && !!errors.email}
                    />
                    {showErrors && errors.email && (
                      <ErrorMessage>{errors.email}</ErrorMessage>
                    )}
                  </Field>
                  <Field>
                    <Label>Phone Number</Label>
                    <Input
                      type="tel"
                      name="phone_number"
                      placeholder="(555) 555-5555"
                      onChange={handlePhoneChange}
                      value={form.phone_number}
                      invalid={showErrors && !!errors.phone_number}
                      maxLength={14}
                    />
                    {showErrors && errors.phone_number && (
                      <ErrorMessage>{errors.phone_number}</ErrorMessage>
                    )}
                  </Field>
                </div>
                <Field>
                  <Label>Relationship</Label>
                  <Select
                    name="relationship"
                    onChange={handleChange}
                    invalid={showErrors && !!errors.relationship}
                    value={form.relationship}
                  >
                    <option value="" disabled>
                      Select relationship
                    </option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Grandfather">Grandfather</option>
                    <option value="Grandmother">Grandmother</option>
                    <option value="Legal Guardian">Legal Guardian</option>
                    <option value="Aunt">Aunt</option>
                    <option value="Uncle">Uncle</option>
                    <option value="Cousin">Cousin</option>
                    <option value="Family Friend">Family Friend</option>
                    <option value="Other">Other</option>
                  </Select>
                  {showErrors && errors.relationship && (
                    <ErrorMessage>{errors.relationship}</ErrorMessage>
                  )}
                </Field>
                <SwitchField>
                  <Label>Primary guardian</Label>
                  <Switch
                    color="sprout"
                    name="primary_guardian"
                    checked={primaryGuardian}
                    onChange={setPrimaryGuardian}
                  />
                  <Description>
                    Choose if this guardian a the primary guardian for the
                    student.
                  </Description>
                </SwitchField>
                {!guardian.guardianProperties[0].finishedSetup && (
                  <SwitchField>
                    <Label>Resend email invite</Label>
                  <Switch
                    color="sprout"
                    name="invite_via_email"
                    checked={inviteViaEmail}
                    onChange={setInviteViaEmail}
                  />
                    <Description>
                      Resend an email invite to the guardian with a link to setup
                      their account.
                    </Description>
                  </SwitchField>
                )}
              </FieldGroup>
            </Fieldset>
          </DialogBody>
          <DialogActions className="flex justify-between">
            <Button color="red" type="button" onClick={() => setDeleteDialogOpen(true)}>
              Delete guardian
            </Button>
            <div className="flex gap-2">
              <Button outline onClick={() => handleClose()}>
                Cancel
            </Button>
            <Button type="submit" disabled={submitting} color="sprout">
              {!submitting ? "Save" : "Saving..."}
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
      <Toaster/>
    </>
  );
}
